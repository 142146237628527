import { Dropdown, Menu, Table } from 'antd';
import { groupBy, orderBy } from 'lodash';
import React, { useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import { FaEllipsisV, FaFilePdf } from 'react-icons/fa';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import PrintVehicleCosts from '../PrintVehicleCosts/PrintVehicleCosts';

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled(FaEllipsisV)`
  cursor: pointer;
`;

export default function Ranking(props) {
  const componentRef = useRef(null);

  const { serviceOrders, serviceOrdersPreventive, date } = props;

  const orders = [...serviceOrders, ...serviceOrdersPreventive];

  const groupedByVehicle = groupBy(orders, (order) => order.vehicle.plate);

  const vehicles = [];
  for (let key in groupedByVehicle) {
    vehicles.push({
      plate: key,
      cost: groupedByVehicle[key]
        .map(
          (order) =>
            order.cost +
            (order.laborCost || 0) +
            parseFloat(order.parts.reduce((a, b) => a + b.meanUnitPrice * b.amount, 0)),
        )
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    });
  }

  const orderedVehicles = orderBy(vehicles, ['cost'], ['desc']);

  const formatVehicles = orderedVehicles.map((vehicle, index) => ({
    ...vehicle,
    cost: (
      <CurrencyFormat
        value={vehicle.cost}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    ),
    key: index + 1,
  }));

  const columns = [
    {
      title: 'Placa',
      dataIndex: 'plate',
      key: 'plate',
    },
    {
      title: 'Custo (R$)',
      dataIndex: 'cost',
      key: 'cost',
    },
  ];

  return (
    <>
      <TitleContainer>
        <p className="title">Ranking de veículos</p>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <ReactToPrint
                  trigger={() => (
                    <span>
                      <FaFilePdf /> Exportar relatório PDF
                    </span>
                  )}
                  content={() => componentRef.current}
                  documentTitle="Infleet | Manutenção - Relatório de Custos"
                />
                <div style={{ display: 'none' }}>
                  <PrintVehicleCosts
                    forRef={componentRef}
                    serviceOrders={serviceOrders}
                    serviceOrdersPreventive={serviceOrdersPreventive}
                    date={date}
                  />
                </div>
              </Menu.Item>
            </Menu>
          }
        >
          <Icon />
        </Dropdown>
      </TitleContainer>
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={formatVehicles}
        scroll={{ y: 300 }}
      />
    </>
  );
}
