import { useHistory, useParams } from 'react-router-dom';
import { db } from '../../../firebaseConfig';
import { Modal, Select, Tag } from 'antd';
import HistoricPart from '../HistoricPart';
import React, { useEffect, useState, useCallback } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import FormPart from './FormPart';
import FormPurchase from './FormPurchase';
import styled from 'styled-components';
import { Title, Subtitle } from '../../../components/DetailPageTitle/styles';

const Question = styled.h2`
  margin-bottom: 1rem;
`;

const { Option } = Select;

const FormContainer = ({ organization }) => {
  const history = useHistory();
  const { id } = useParams();
  const [parts, setParts] = useState([]);
  const [selectedPart, setPart] = useState(undefined);
  const [modal, setModal] = useState(false);
  const [purchases, setPurchases] = useState(history.location.state);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    if (!id) {
      await db
        .collection('organizations')
        .doc(organization)
        .collection('parts')
        .onSnapshot((partsSnap) => {
          const parts = partsSnap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setParts(parts);
        });

      setLoading(false);
    } else {
      const partSnap = await db
        .collection('organizations')
        .doc(organization)
        .collection('parts')
        .doc(id)
        .get();

      setPart(partSnap.data());

      setLoading(false);
    }
  }, [organization, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onChange = async (value, option) => {
    const part = option.props.part;

    if (!part.id) {
      setPart(part);
      setModal(true);
    } else {
      setPart(part);
    }
  };

  const onSearch = (value) => {
    const newPart = {
      name: value,
      category: '',
      unitControl: '',
    };

    const fetchedParts = parts.filter((el) => el.id);

    if (value === '') {
      setParts(fetchedParts);
    } else {
      setParts([newPart, ...fetchedParts]);
    }
  };

  return (
    <div>
      <div className="arrow-left">
        <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <FaArrowLeft style={{ fontSize: '2em', color: '#AAA' }} />
        </span>
      </div>
      <Title>{id ? 'Editar item' : 'Inserir novo item'}</Title>
      {!id ? (
        <>
          <div style={{ marginBottom: '1rem' }} className="part-form-container">
            <Question>Qual o nome da peça ou insumo que você gostaria de lançar?</Question>
            <p className="part-instruction">
              Digite o item e verifique se o mesmo já existe na base de dados
            </p>
            <div className="part-input-container">
              <Select
                showSearch
                value={selectedPart ? selectedPart.name : undefined}
                size="large"
                style={{ width: '100%' }}
                placeholder="Ex.: Fluido de Freio"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.props.part.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {parts.map((option, index) => {
                  if (!option.id && index === 0) {
                    return (
                      <Option key={index} value={option.name} part={{ ...option }}>
                        {option.name} {<Tag color="orange">Novo</Tag>}
                      </Option>
                    );
                  } else {
                    return (
                      <Option key={index} value={option.name} part={option}>
                        {option.name}
                      </Option>
                    );
                  }
                })}
              </Select>
            </div>
          </div>

          {selectedPart && <FormPurchase part={selectedPart} organization={organization} />}
          {modal ? (
            <Modal
              title="Criar uma nova peça"
              visible={modal}
              onCancel={() => {
                setModal(false);
                setPart(undefined);
              }}
              footer={null}
            >
              <FormPart
                part={selectedPart}
                setPart={setPart}
                organization={organization}
                setModal={setModal}
              />
            </Modal>
          ) : null}
        </>
      ) : loading ? (
        <div style={{ textAlign: 'center' }}>Carregando...</div>
      ) : (
        <>
          <div className="part-info">
            <Subtitle>Informações da peça ou insumo</Subtitle>
            <p className="part-instruction">Insira as informações referente a esse item</p>
            <FormPart id={id} part={selectedPart} organization={organization} history={history} />
          </div>
          <HistoricPart
            purchases={purchases}
            setPurchases={setPurchases}
            organization={organization}
          />
        </>
      )}
    </div>
  );
};

export default FormContainer;
