import { Card, Col } from 'antd';
import moment from 'moment';
import React from 'react';
import Loading from '../../components/Loading';

export default function MeanTime(props) {
  const { serviceOrders, serviceOrdersPreventive, loading } = props;

  const orders = [...serviceOrders, ...serviceOrdersPreventive];

  const days = orders
    .map((order) => {
      if (order.initDate && order.finalDate) {
        const initial = moment(order.initDate);
        const final = moment(order.finalDate);
        const difference = final.diff(initial, 'days');

        return difference > 0 ? difference : 0;
      } else {
        return 0;
      }
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const dataVehicle = orders
    .map((order) => order.vehicle.id)
    .filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    });

  let meanTime = (days / dataVehicle.length).toFixed(0);

  if (!isFinite(meanTime)) meanTime = 0;

  return (
    <Col span={8}>
      <Card size="small" title="Tempo médio em manutenção por veículo" loading={false}>
        {loading ? (
          <Loading type={'spin'} color={'#001132'} width={32} />
        ) : (
          <p className="card-number">{meanTime === '1' ? `${meanTime} dia` : `${meanTime} dias`}</p>
        )}
      </Card>
    </Col>
  );
}
