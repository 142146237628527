import groupBy from 'lodash/groupBy';
import React, { useEffect, useState } from 'react';
import { FaTruck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Headline } from '../../components/Headline';
import { AssetName } from './components/AssetName';

export default function FinalizedServices({ finalizedServices }) {
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    const filteredFinalizedServices = finalizedServices.filter((s) => !!s.vehicle.id);

    const groupedByVehicle = groupBy(filteredFinalizedServices, (service) => service.vehicle.plate);
    const sortedVehicles = Object.entries(groupedByVehicle)
      .map(([plate, service]) => ({
        plate: plate,
        vehicle: service[0].vehicle,
        services: service,
      }))
      .sort(function (a, b) {
        if (a.plate < b.plate) {
          return -1;
        }
        if (a.plate > b.plate) {
          return 1;
        }
        return 0;
      });

    setVehicles(sortedVehicles);
  }, [finalizedServices, setVehicles]);

  return (
    <div className="column-container">
      <Headline>Serviços realizados</Headline>
      {vehicles.map((card) => (
        <div className="column-card" key={card.vehicle.id}>
          <div style={{ display: 'flex', alignContent: 'center' }}>
            <FaTruck style={{ color: '#AAA', marginTop: '0.2rem' }} />
            <Link
              to={{
                pathname: `/vehicles-and-equipments/${card.vehicle.id}`,
              }}
            >
              <AssetName>{card.plate}</AssetName>
            </Link>
          </div>
          <div>
            <p className="column-services">Serviços</p>
            <h6 className="column-services-number">{card.services ? card.services.length : 0}</h6>
          </div>
        </div>
      ))}
    </div>
  );
}
