import * as Yup from 'yup';
import { db } from '../../../firebaseConfig';
import { ErrorMessage, Formik } from 'formik';
import { Col, Input, Row, Select } from 'antd';
import React from 'react';
import { CATEGORIES } from '../../../constants';

const { Option } = Select;

const FormPart = ({ id, part, organization, setPart, setModal, history }) => {
  const handleCreatePart = async (values) => {
    const { name, category, unitControl } = values;

    const part = {
      name,
      category,
      unitControl,
    };

    const partRef = await db
      .collection('organizations')
      .doc(organization)
      .collection('parts')
      .add(part);

    const partWithId = {
      ...part,
      id: partRef.id,
    };

    setPart(partWithId);
    setModal(false);
  };

  const handleEditPart = async (values) => {
    await db.collection('organizations').doc(organization).collection('parts').doc(id).set(values);

    history.push('/parts');
  };

  const PartSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    category: Yup.string().required('Campo obrigatório'),
    unitControl: Yup.string().required('Campo obrigatório'),
  });

  return (
    <Formik
      initialValues={part}
      validationSchema={PartSchema}
      onSubmit={(values, { setSubmitting }) => {
        id ? handleEditPart(values) : handleCreatePart(values);
        setSubmitting(false);
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div className="part-info-field">
              <label className="label-form" htmlFor="name">
                Nome:
                <ErrorMessage name="name">
                  {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                </ErrorMessage>
              </label>
              <Input
                name="name"
                onChange={handleChange}
                defaultValue={values.name}
                placeholder="Ex.: Fluido de Freio"
              />
            </div>
            <Row gutter={16}>
              <Col span={12}>
                <div className="part-info-field">
                  <label className="label-form" htmlFor="category">
                    Categoria:
                    <ErrorMessage name="category">
                      {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                    </ErrorMessage>
                  </label>
                  <Select
                    name="category"
                    showSearch
                    style={{ width: '100%' }}
                    defaultValue={values.category}
                    placeholder="Ex.: MOTOR"
                    optionFilterProp="children"
                    onChange={(value) => setFieldValue('category', value)}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {CATEGORIES.map((el, index) => (
                      <Option key={index} value={el}>
                        {el}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
              <Col span={12}>
                <div className="part-info-field">
                  <label className="label-form" htmlFor="unitControl">
                    Unidade de Controle:
                    <ErrorMessage name="unitControl">
                      {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                    </ErrorMessage>
                  </label>
                  <Select
                    name="unitControl"
                    showSearch
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    onChange={(value) => setFieldValue('unitControl', value)}
                    defaultValue={values.unitControl}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="KG">KG</Option>
                    <Option value="L">L</Option>
                    <Option value="UNIDADE">UNIDADE</Option>
                  </Select>
                </div>
              </Col>
            </Row>
            <div style={{ textAlign: 'right' }}>
              <button key="submit" className="newVehicle" type="submit" disabled={isSubmitting}>
                Salvar peça
              </button>
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};

export default FormPart;
