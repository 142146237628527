import { message } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ContainerTitle, Title } from '../../components/PageTitle/styles';
import { db } from '../../firebaseConfig';
import { usePlans } from './hooks/usePlans';
import PlansTable from './PlansTable';

const deletePlan = async (organization, id) => {
  try {
    return await db
      .collection('organizations')
      .doc(organization)
      .collection('maintenanceTemplate')
      .doc(id)
      .delete();
  } catch (err) {
    console.error(err.message);
    throw new Error('Failed attempt to delete plan');
  }
};

export default function Plans({ organization }) {
  const history = useHistory();
  const { plans, loading } = usePlans(organization);

  const handleDelete = async (id) => {
    await deletePlan(organization, id);
    message.success('Plano removido com sucesso.');
  };

  return (
    <>
      <div>
        <ContainerTitle>
          <Title>Planos de Manutenção</Title>
          <div>
            <button onClick={() => history.push('/plans/new')} className="newVehicle">
              Novo plano
            </button>
          </div>
        </ContainerTitle>
        <div>
          <PlansTable plans={plans} handleDelete={handleDelete} loading={loading} />
        </div>
      </div>
    </>
  );
}
