import styled from 'styled-components';
import { SubmitButton } from 'formik-antd';

export const SectionContainer = styled.div`
  width: 50vw;
  padding: 2rem;
  margin: 1rem auto;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;

export const CustomSubmitButton = styled(SubmitButton)`
  background-color: rgb(50, 182, 80);
  padding: 0.75rem 2rem;
  height: 3rem;
  border: 1px solid rgb(46, 165, 73);
  border-radius: 4px;
  color: #fff;
  margin: 1rem 0;
`;

export const SubmitContainer = styled.div`
  text-align: center;
`;
