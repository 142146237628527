import { Card, Col } from 'antd';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import Loading from '../../components/Loading';

export default function MeanCost(props) {
  const { serviceOrders, serviceOrdersPreventive, loading } = props;

  const orders = [...serviceOrders, ...serviceOrdersPreventive];

  const dataCost = orders
    .map(
      (order) =>
        order.cost +
        (order.laborCost || 0) +
        parseFloat(order.parts.reduce((a, b) => a + b.meanUnitPrice * b.amount, 0)),
    )
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const dataVehicle = orders
    .map((order) => order.vehicle.id)
    .filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    });

  let meanCost = dataCost / dataVehicle.length;

  if (!isFinite(meanCost)) meanCost = 0;

  return (
    <Col span={8}>
      <Card size="small" title="Custo médio por veículo" loading={false}>
        {loading ? (
          <Loading type={'spin'} color={'#001132'} width={32} />
        ) : (
          <p className="card-number">
            <CurrencyFormat
              value={meanCost}
              displayType={'text'}
              thousandSeparator={'.'}
              prefix={'R$ '}
              decimalSeparator={','}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </p>
        )}
      </Card>
    </Col>
  );
}
