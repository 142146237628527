import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  height: 300px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Tag = styled.h5`
  display: inline-block;
  padding: 1rem;
  background-color: #0e2c66;
  color: white;
  border-radius: 12px;
  font-weight: bold;
`;

export const Logo = styled.img`
  width: 200px;
`;

export const Title = styled.p`
  margin: 2rem 0 0 0;
  padding: 0;
`;

export const Content = styled.div`
  margin-top: 2rem;
`;

export const Label = styled.label`
  color: gray;
  display: inline-block;
`;

export const TotalCost = styled.p`
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 3rem;
`;

export const OrderContainer = styled.div`
  margin-top: 1rem;
  padding: 0 0 0 15rem;

  @media print {
    footer {
      page-break-after: always;
    }
  }
`;

export const OrderTitle = styled.h6`
  margin-top: 2rem;
  color: #7e5ade;
  font-weight: bold;
`;

export const OrderInfoContainer = styled.div`
  margin-top: 3rem;
`;

export const CustomRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const InfoContainer = styled.div`
  margin-top: 2rem;
  width: 80%;
`;

export const ServicesContainer = styled.div`
  margin-top: 2rem;
  margin-right: 2rem;
`;

export const SubTitle = styled.h6`
  font-weight: bold;
  margin-bottom: 1rem;
`;
