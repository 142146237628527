import { Select } from 'antd';
import { useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import {
  Horimeter_History,
  Odometer_History,
  useListAllVehiclesQuery,
  Vehicle,
} from '../../../../../generated/graphql';

const { Option } = Select;

export default function SelectVehicle(props: any): ReactElement {
  const { handleVehiclePlan } = props;
  const [result] = useListAllVehiclesQuery();

  const { data } = result;

  const vehicles = data?.vehicle || [];

  const { values, setFieldValue }: any = useFormikContext();

  const setMeasures = (value: Vehicle) => {
    const odometer_histories = value.odometer_histories;
    const horimeter_histories = value.horimeter_histories;
    const date = values.initDate;

    const odometer =
      odometer_histories
        .filter(
          (register: Odometer_History) =>
            new Date(date).getTime() >= new Date(register.measured_at).getTime(),
        )
        .sort(
          (a: Odometer_History, b: Odometer_History) =>
            new Date(b.measured_at).getTime() - new Date(a.measured_at).getTime(),
        )[0]?.current ?? 0;

    const horimeter =
      horimeter_histories
        .filter(
          (register: Horimeter_History) =>
            new Date(date).getTime() >= new Date(register.measured_at).getTime(),
        )
        .sort(
          (a: Horimeter_History, b: Horimeter_History) =>
            new Date(b.measured_at).getTime() - new Date(a.measured_at).getTime(),
        )[0]?.current ?? 0;

    setFieldValue('vehicle', value);
    setFieldValue('odometer', odometer);
    setFieldValue('horimeter', horimeter);
  };

  return (
    <div>
      <label className="label-form" htmlFor="vehicle">
        Veículo:
      </label>

      <Select
        style={{ width: '100%' }}
        value={values?.vehicle?.plate}
        onChange={async (_, event: any) => {
          setMeasures(event.vehicle);
          handleVehiclePlan(event.vehicle.id, setFieldValue);
        }}
      >
        {vehicles.map((vehicle, index) => (
          <Option key={index} value={vehicle.id} vehicle={vehicle}>
            {vehicle.plate}
          </Option>
        ))}
      </Select>
    </div>
  );
}
