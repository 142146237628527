import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Popconfirm, Row, Table } from 'antd';
import { flatten } from 'lodash';
import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';
import { FaPen, FaTrash } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { ContainerTitle, Title } from '../../components/PageTitle/styles';
import { CATEGORIES } from '../../constants';
import { db } from '../../firebaseConfig';

class Parts extends Component {
  state = {
    parts: [],
    purchases: [],
    serviceOrders: [],
    serviceOrdersPreventive: [],
    deletablePart: undefined,
    showDelete: false,
    loading: true,
    searchText: '',
    searchedColumn: '',
  };

  componentDidMount = async () => {
    this.getOrders();
    this.getPurchases();
    this.getParts();
  };

  getParts = () => {
    const { organization } = this.props;
    console.log(organization);
    if (!organization) return;

    db.collection('organizations')
      .doc(organization)
      .collection('parts')
      .onSnapshot((snapshot) => {
        const parts = snapshot.docs.map((doc, index) => ({
          id: doc.id,
          number: index + 1,
          ...doc.data(),
        }));
        this.setState({ parts, loading: false });
      });
  };

  getPurchases = () => {
    const { organization } = this.props;
    if (!organization) return;

    db.collection('organizations')
      .doc(organization)
      .collection('purchases')
      .onSnapshot((snapshot) => {
        const purchases = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          date: doc.data().date.toDate(),
        }));
        this.setState({ purchases });
      });
  };

  getOrders = () => {
    const { organization } = this.props;
    if (!organization) return;

    db.collection('organizations')
      .doc(organization)
      .collection('serviceOrders')
      .onSnapshot((querySnapshot) => {
        const serviceOrders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          initDate: doc.data().initDate ? doc.data().initDate.toDate() : null,
          finalDate: doc.data().finalDate ? doc.data().finalDate.toDate() : null,
        }));
        const formatServiceOrders = serviceOrders.sort(function (a, b) {
          let dateA = a.initDate,
            dateB = b.initDate;
          return dateB - dateA;
        });
        this.setState({ serviceOrders: formatServiceOrders });
      });

    db.collection('organizations')
      .doc(organization)
      .collection('serviceOrdersPreventive')
      .onSnapshot((querySnapshot) => {
        const serviceOrdersPreventive = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          initDate: doc.data().initDate ? doc.data().initDate.toDate() : null,
          finalDate: doc.data().finalDate ? doc.data().finalDate.toDate() : null,
        }));
        const formatServiceOrdersPreventive = serviceOrdersPreventive.sort(function (a, b) {
          let dateA = a.initDate,
            dateB = b.initDate;
          return dateB - dateA;
        });
        this.setState({
          serviceOrdersPreventive: formatServiceOrdersPreventive,
        });
      });
  };

  handleCloseDelete = () => {
    this.setState({ showDelete: false });
  };

  handleDelete = async (id) => {
    const { organization } = this.props;
    await db
      .collection('organizations')
      .doc(organization)
      .collection('parts')
      .doc(id)
      .delete()
      .catch((err) => console.log(err));
    this.setState({ deletablePart: undefined, showDelete: false });
  };

  formatParts = (correctives, preventives, parts, purchases) => {
    const partsCorrective = flatten(correctives.map((o) => o.parts || []));
    const partsPreventive = flatten(preventives.map((o) => o.parts || []));

    const usedParts = [...partsCorrective, ...partsPreventive];

    const formatParts = parts.map((part) => {
      const filteredPurchases = purchases.filter((purchase) => purchase.parts[0].id === part.id);
      const amountPurchased = filteredPurchases.reduce((a, b) => a + b.amount, 0);
      const valuePurchased = filteredPurchases.reduce((a, b) => a + b.amount * b.unitPrice, 0);

      const filteredUsedParts = usedParts.filter((p) => {
        if (p.length === 0) return false;

        return part.id === p.id;
      });

      const amountSpent = filteredUsedParts.reduce((a, b) => a + b.amount, 0);
      const valueSpent = filteredUsedParts.reduce((a, b) => a + b.amount * b.meanUnitPrice, 0);

      return {
        ...part,
        purchases: filteredPurchases || [],
        totalAmount: amountPurchased - amountSpent,
        totalValue: valuePurchased - valueSpent,
      };
    });

    return formatParts;
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Procurar...`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Procurar
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Limpar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const { purchases, serviceOrders, serviceOrdersPreventive, parts, loading } = this.state;

    const tableParts = this.formatParts(serviceOrders, serviceOrdersPreventive, parts, purchases);

    const columnsTable = [
      {
        title: '#',
        dataIndex: 'number',
        key: 'number',
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Categoria',
        dataIndex: 'category',
        key: 'category',
        filters: CATEGORIES.map((option) => ({ text: option, value: option })),
        onFilter: (value, record) => record.category.indexOf(value) === 0,
        sorter: (a, b) => a.category.length - b.category.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Unidade de Controle',
        dataIndex: 'unitControl',
        key: 'unitControl',
        filters: [
          { text: 'KM', value: 'KM' },
          { text: 'H', value: 'H' },
          { text: 'UNIDADE', value: 'UNIDADE' },
        ],
        onFilter: (value, record) => record.category.indexOf(value) === 0,
      },
      {
        title: 'Quantidade total',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        sorter: (a, b) => a.totalAmount - b.totalAmount,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Valor em estoque',
        dataIndex: 'totalValue',
        key: 'totalValue',
        sorter: (a, b) => a.totalValue - b.totalValue,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Ações',
        key: 'action',
        render: (text, record) => (
          <>
            <FaPen
              style={{ cursor: 'pointer', marginRight: 12 }}
              onClick={() => this.props.history.push(`/parts/${record.id}`, record.purchases)}
            />
            <Popconfirm
              title="Tem certeza que deseja deletar este item?"
              onConfirm={() => this.handleDelete(record.id)}
            >
              <FaTrash style={{ cursor: 'pointer' }} />
            </Popconfirm>
          </>
        ),
      },
    ];

    return (
      <div>
        <ContainerTitle>
          <Title>Peças e Estoque</Title>
          <button onClick={() => this.props.history.push('/parts/new')} className="newOrder">
            Lançar novo item
          </button>
        </ContainerTitle>
        <Row>
          <Col style={{ height: '80vh', overflowY: 'auto' }} span={24}>
            <Table
              rowKey="id"
              pagination={false}
              dataSource={tableParts}
              columns={columnsTable}
              loading={loading}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Parts);
