import { Field } from 'formik';
import styled, { css } from 'styled-components';
import { Select } from 'antd';

export const FieldContainer = styled.div`
  height: 120px;
`;

export const CustomField = styled(Field)`
  display: inline-block;
  width: 100%;
  height: 44px;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;

  ${({ touched, error }) =>
    touched && error
      ? css`
          border: 1px solid rgb(200, 0, 0, 0.5);
          outline-color: rgb(200, 0, 0, 0.5);
          &:focus {
            outline-color: rgb(200, 0, 0, 0.5);
          }
        `
      : css`
          &:focus {
            outline-color: rgb(42, 108, 249, 0.5);
          }
        `}

  &::placeholder {
    color: #bbb;
  }
`;

export const CustomSelect = styled(Select)`
  width: 100%;
`;

export const Error = styled.div`
  margin-top: 0.25rem;
  text-align: left;
  color: red;
  font-size: 12px;
  font-weight: bold;
`;

export const SubmitButton = styled.button`
  background-color: rgb(50, 182, 80);
  border: 1px solid rgb(46, 165, 73);
  border-radius: 4px;
  color: #fff;
  padding: 12px;
  cursor: pointer;
`;
