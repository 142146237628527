import { Col, notification, Popconfirm, Row, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useEffect, useState } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import styled from 'styled-components';
import { AuthContext } from '../../../../contexts/AuthProvider';
import { db } from '../../../../firebaseConfig';
import useServices from '../../hooks/useServices';
import { AddButton } from './components/AddButton';
import Subtitle from './components/DetailSubtitle';
import ImportService from './Import';
import ServiceForm from './ServiceForm';

const ActionsContainer = styled.div`
  display: flex;
`;

export default function MaintenancePlan(props) {
  const { vehicle } = props;
  const { firebaseOrganizationId } = useContext(AuthContext);

  const { services, setServices } = useServices(vehicle);

  const [formModal, setFormModal] = useState(false);
  const [editableService, setEditableService] = useState(null);
  const [data, setData] = useState(services);

  useEffect(() => {
    setData(services);
  }, [services]);

  console.log('listed services', services);

  const handleDelete = async (record) => {
    try {
      await db
        .collection('organizations')
        .doc(firebaseOrganizationId)
        .collection('maintenancePlan')
        .doc(record.id)
        .delete()
        .then(() => {
          const servicesList = [...services.filter(({ id }) => id !== record.id)];
          setServices(servicesList);
        });
    } catch (e) {
      console.error(e);
      notification['error']({
        message: 'Erro ao excluir serviço',
        description: 'Recarregue a página e tente novamente. Contate o suporte',
      });
    }
  };

  const closeModal = () => {
    setEditableService(null);
    setFormModal(false);
  };

  const columns = [
    {
      title: 'Serviço',
      dataIndex: 'service',
      width: 350,
    },
    {
      title: 'Categoria',
      dataIndex: 'category',
    },
    {
      title: 'Unidade de controle',
      dataIndex: 'unitControl',
    },
    {
      title: 'Frequência (KM ou H)',
      dataIndex: 'frequency',
    },
    {
      title: 'Periodicidade (dias)',
      dataIndex: 'period',
    },
    {
      title: 'Ações',
      render: (_, record) => {
        return (
          <>
            <FaPen
              style={{ cursor: 'pointer', marginRight: 12 }}
              onClick={() => {
                setEditableService(record);
                setFormModal(true);
              }}
            />
            <Popconfirm
              title="Você gostaria de deletar esse serviço?"
              onConfirm={() => handleDelete(record)}
            >
              <FaTrash style={{ cursor: 'pointer' }} />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  if (!firebaseOrganizationId) return <p>Carregando...</p>;

  return (
    <div>
      <Subtitle>Plano de Manutenção</Subtitle>
      <ActionsContainer>
        <AddButton onClick={() => setFormModal(true)}>Adicionar</AddButton>
        <ImportService
          vehicle={vehicle}
          organization={firebaseOrganizationId}
          setServices={setServices}
        />
      </ActionsContainer>
      <Row>
        <Col span={24}>
          <Table dataSource={data} columns={columns} rowKey="id" />
        </Col>
      </Row>
      <Modal
        destroyOnClose={true}
        width={750}
        visible={formModal}
        title={editableService ? 'Editar um serviço' : 'Adicionar um serviço'}
        footer={null}
        onCancel={closeModal}
      >
        <ServiceForm
          editableService={editableService}
          organization={firebaseOrganizationId}
          vehicle={vehicle}
          closeModal={closeModal}
          setServices={setServices}
        />
      </Modal>
    </div>
  );
}
