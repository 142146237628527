import { useContext, useEffect, useState, useCallback } from 'react';
import { AuthContext } from '../../../contexts/AuthProvider';
import { db } from '../../../firebaseConfig';
import { Service, Vehicle } from '../../../types';

export default function useServices(vehicle: Vehicle) {
  const [services, setServices] = useState<Service[] | undefined>();
  const { firebaseOrganizationId } = useContext(AuthContext);

  const fetchServices = useCallback(async () => {
    await db
      .collection('organizations')
      .doc(firebaseOrganizationId)
      .collection('maintenancePlan')
      .where('vehicle.id', '==', vehicle?.id)
      .get()
      .then((querySnapshot) => {
        const services = querySnapshot.docs.map((doc, index) => {
          return {
            ...doc.data(),
            id: doc.id,
          } as Service;
        });
        setServices(services);
      });
  }, [firebaseOrganizationId, vehicle]);

  useEffect(() => {
    if (firebaseOrganizationId) {
      fetchServices();
    }
  }, [firebaseOrganizationId, fetchServices]);

  return { services, setServices };
}
