import { Input } from 'antd';
import React, { useState } from 'react';
import { FaPen } from 'react-icons/fa';
import styled from 'styled-components';

const Info = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  height: 2rem;
  cursor: pointer;
`;

const EditIcon = styled(FaPen)`
  margin-left: 0.5rem;
  color: #aaa;
`;

const ButtonContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
`;

const SaveButton = styled.button`
  background-color: #7758c8;
  font-size: 12px;
  border: 1px solid #7758c8;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
`;

const CancelButton = styled.button`
  background-color: transparent;
  font-size: 12px;
  color: red;
  border: none;
  margin-right: 0.5rem;
  cursor: pointer;
`;

const ClickToAdd = styled.p`
  color: gray;
  cursor: pointer;
  border-bottom: 1px dotted gray;
`;

export default function EditableInfo(props: any) {
  const { property, propertyValue, handleUpdate, handleCreate } = props;

  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState(propertyValue);

  return (
    <div>
      {(value || value === 0) && !isEditing ? (
        <Container
          onClick={() => {
            setEditing(true);
          }}
        >
          <Info>{propertyValue}</Info>
          <EditIcon />
        </Container>
      ) : (
        <div>
          {isEditing ? (
            <div>
              <Input
                type="text"
                value={value}
                onChange={(e) => {
                  console.log(e);
                  setValue(e.target.value);
                }}
              />
              <ButtonContainer>
                <CancelButton onClick={() => setEditing(false)}>Cancelar</CancelButton>
                <SaveButton
                  onClick={() => {
                    handleUpdate && handleUpdate(property, value);
                    setEditing(false);
                    handleCreate && handleCreate(value);
                  }}
                >
                  Salvar
                </SaveButton>
              </ButtonContainer>
            </div>
          ) : (
            <ClickToAdd onClick={() => setEditing(true)}>Clique aqui para adicionar</ClickToAdd>
          )}
        </div>
      )}
    </div>
  );
}
