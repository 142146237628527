import { Col, Row } from 'antd';
import React from 'react';
import { ContainerTitle, Title } from '../../components/PageTitle/styles';
import RealizedServices from './FinalizedServices';
import OngoingServices from './OngoingServices';
import PendentServices from './PendentServices';
import UrgentServices from './UrgentServices';
import { useCalculateSummaryData } from './useCalculateSummaryData';
import { Instruction } from '../../components/Instruction';

const LIMIT_ODOMETER = 1000;
const LIMIT_HORIMETER = 100;
const LIMIT_DAYS = 15;

export default function Summary(props) {
  const { organization } = props;

  const {
    pendentServices,
    urgentServices,
    ongoingServices,
    finalizedServices,
    preventiveOrders,
    correctiveOrders,
  } = useCalculateSummaryData(organization, LIMIT_ODOMETER, LIMIT_HORIMETER, LIMIT_DAYS);

  return (
    <div>
      <ContainerTitle>
        <Title>Página inicial</Title>
      </ContainerTitle>
      <Row>
        <Instruction>Verifique a lista de veículos e serviços pendentes</Instruction>
      </Row>
      <Row>
        <Col span={6}>
          <PendentServices pendentServices={pendentServices} preventiveOrders={preventiveOrders} />
        </Col>
        <Col span={6}>
          <UrgentServices urgentServices={urgentServices} preventiveOrders={preventiveOrders} />
        </Col>
        <Col span={6}>
          <OngoingServices
            ongoingServices={ongoingServices}
            preventiveOrders={preventiveOrders}
            correctiveOrders={correctiveOrders}
          />
        </Col>
        <Col span={6}>
          <RealizedServices finalizedServices={finalizedServices} />
        </Col>
      </Row>
    </div>
  );
}
