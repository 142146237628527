import { ResponsiveBar } from '@nivo/bar';
import { groupBy, orderBy } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';

export default class CostChart extends Component {
  state = {
    data: [],
  };

  render() {
    const { serviceOrders, serviceOrdersPreventive } = this.props;

    const orders = orderBy([...serviceOrders, ...serviceOrdersPreventive], ['initDate'], ['desc']);

    const groupedByMonth = groupBy(orders, (order) =>
      moment(order['initDate'], 'DD/MM/YYYY').startOf('month').format('MMMM'),
    );

    const data = [];
    for (let key in groupedByMonth) {
      data.push({
        month: key,
        'Custo (R$)': groupedByMonth[key]
          .map(
            (order) =>
              parseFloat(order.cost) +
              (parseFloat(order.laborCost) +
                parseFloat(order.parts.reduce((a, b) => a + b.meanUnitPrice * b.amount, 0)) || 0),
          )
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
          .toFixed(2),
      });
    }
    return (
      <>
        <p style={{ marginBottom: '-1rem' }} className="title">
          Custo total x Mês
        </p>
        <div style={{ height: '40vh' }}>
          <MyResponsiveBar data={data} />
        </div>
      </>
    );
  }
}

const MyResponsiveBar = ({ data }) => (
  <ResponsiveBar
    data={data}
    keys={['Custo (R$)']}
    indexBy="month"
    margin={{
      top: 50,
      right: 130,
      bottom: 50,
      left: 80,
    }}
    padding={0.6}
    colors={() => '#195c79'}
    borderColor={{
      from: 'color',
      modifiers: [['darker', 1.6]],
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Meses',
      legendPosition: 'middle',
      legendOffset: 40,
    }}
    axisLeft={{
      tickValues: 5,
      tickSize: 5,
      tickPadding: 0,
      tickRotation: 0,
      legend: 'Custo (R$)',
      legendPosition: 'middle',
      legendOffset: -60,
    }}
    label={null}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
);
