import { Col, Row, Table } from 'antd';
import React from 'react';
import { formatDateBRL } from '../helpers/format-date';

export const PrintableServiceOrder = ({ record }) => {
  const cost =
    record.cost +
    (record.laborCost || 0) +
    (record?.parts?.reduce((a, b) => a + b.meanUnitPrice * b.amount, 0) || 0);

  return (
    <>
      <h2>Ordem de Serviço - Nº {record.number}</h2>
      <Row gutter={16}>
        <Col span={8}>
          <p>
            <b>Custo: </b> R$ {cost.toFixed(2)}
          </p>
        </Col>
        <Col span={8}>
          <p>
            <b>Descrição: </b> {record.description}
          </p>
        </Col>
        <Col span={8}>
          <p>
            <b>Motorista: </b> {record.driver}
          </p>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <p>
            <b>Tipo: </b> {record.maintenanceType}
          </p>
        </Col>
        <Col span={8}>
          <p>
            <b>Odometro: </b> {record.odometer}
          </p>
        </Col>
        <Col span={8}>
          <p>
            <b>Estado: </b> {record.state}
          </p>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <p>
            <b>Data inicio: </b> {record.initDate ? record.initDate.toLocaleString('pt-br') : ''}
          </p>
        </Col>
        <Col span={12}>
          <p>
            <b>Data fim: </b> {record.finalDate ? record.finalDate.toLocaleString('pt-br') : ''}
          </p>
        </Col>
      </Row>

      <hr />
      <h3>Fornecedor</h3>
      <Row gutter={16}>
        <Col span={12}>
          <p>
            <b>CNPJ: </b> {record.provider.CNPJ}
          </p>
        </Col>
        <Col span={12}>
          <p>
            <b>Razão Social: </b> {record.provider.company}
          </p>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <p>
            <b>Nome Fantasia: </b> {record.provider.trade}
          </p>
        </Col>
        <Col span={12}>
          <p>
            <b>Endereço: </b> {record.provider.address}
          </p>
        </Col>
      </Row>
      <hr />
      <h3>Veículo</h3>

      <Row gutter={16}>
        <Col span={8}>
          <p>
            <b>Marca: </b> {record.vehicle.brand}
          </p>
        </Col>
        <Col span={8}>
          <p>
            <b>Modelo: </b> {record.vehicle.model}
          </p>
        </Col>
        <Col span={8}>
          <p>
            <b>Ano: </b> {record.vehicle.year}
          </p>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <p>
            <b>Tipo: </b> {record.vehicle.type}
          </p>
        </Col>
        <Col span={8}>
          <p>
            <b>Odometro: </b> {record.vehicle.odometer}
          </p>
        </Col>
        <Col span={8}>
          <p>
            <b>Placa: </b> {record.vehicle.plate}
          </p>
        </Col>
      </Row>
      {record.services && (
        <>
          <hr />
          <h3>Serviços</h3>

          <Table dataSource={record.services} columns={columns} pagination={false} />

          <div style={{ width: 500, position: 'fixed', bottom: 0, right: 0 }}>
            <hr
              style={{
                border: '1px solid',
                color: 'black',
              }}
            />
            <p>Responsável: </p>
          </div>
        </>
      )}
    </>
  );
};

const columns = [
  {
    title: 'Serviço',
    dataIndex: 'service',
    key: 'service',
  },
  {
    title: 'Odômetro',
    dataIndex: 'odometer',
    key: 'odometer',
  },
  {
    title: 'Horímetro',
    dataIndex: 'horimeter',
    key: 'horimeter',
  },
  {
    title: 'Período',
    dataIndex: 'period',
    key: 'period',
    render: (period) => `${period} dias`,
  },
  {
    title: 'Data',
    dataIndex: 'date',
    key: 'date',
    render: (date) => (date ? formatDateBRL(date.toDate()) : '--'),
  },
];
