import { AppstoreFilled, SettingFilled } from '@ant-design/icons';
import { Dropdown, Layout } from 'antd';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { FaGasPump, FaWrench } from 'react-icons/fa';
import styled from 'styled-components';
import ConfigPopover from '../ConfigPopover/ConfigPopover';

const LogoContainer = styled.div`
  height: 32px;
  width: auto;
`;

const Title = styled.span`
  color: #001132;
  font-size: 1rem;
  font-weight: 500;
`;

const menu = (
  <div className="apps">
    <ul className="app-list">
      <li className="app-item">
        <p className="app-instruction">Acesse outras aplicações INFLEET</p>
        <div className="app-item-container">
          <FaGasPump
            style={{
              fontSize: '1.5rem',
              color: '#001132',
            }}
          />
          <a className="app-link" href="https://abastecimento.infleet.com.br">
            Gestão de Abastecimentos
          </a>
        </div>
      </li>
      <li className="app-item">
        <div className="app-item-container">
          <FaWrench
            style={{
              fontSize: '1.5rem',
              color: '#001132',
            }}
          />

          <a className="app-link" href="https://manutencao.infleet.com.br">
            Gestão de Manutenções
          </a>
        </div>
      </li>
    </ul>
  </div>
);

export default function Header() {
  return (
    <>
      <Layout.Header style={{ backgroundColor: '#FFF', padding: '0 20px' }}>
        <Grid fluid>
          <Row between="xs">
            <Col>
              <LogoContainer>
                <Title>Infleet | Gestão de Manutenções</Title>
              </LogoContainer>
            </Col>
            <Col>
              <Dropdown overlay={menu} placement="bottomRight">
                <AppstoreFilled
                  style={{
                    color: '#AAA',
                    cursor: 'pointer',
                    marginRight: '1rem',
                  }}
                />
              </Dropdown>
              <Dropdown overlay={<ConfigPopover />} placement="bottomRight">
                <SettingFilled style={{ color: '#AAA', cursor: 'pointer' }} />
              </Dropdown>
            </Col>
          </Row>
        </Grid>
      </Layout.Header>
    </>
  );
}
