import { flatten } from 'lodash';
import { ExcelOrderServices, Order } from '../../../types';

export const getExcelOrderServices = (orders: Order[]) => {
  const OrdersWithServices = orders.filter((order: Order) => order?.services?.length !== 0);

  if (OrdersWithServices.length !== 0) {
    const sanitizedOrders = orders.map((order) => {
      const services = order.services ?? [];

      const duplicatedOrders = services.map((service) => ({ ...order, service }));

      return duplicatedOrders;
    });

    const flatOrders = flatten(sanitizedOrders);

    if (typeof flatOrders !== 'undefined') {
      const formatOrders = flatOrders.map((order: ExcelOrderServices) => ({
        number: order.number,
        initDate: order.initDate,
        vehicle: order.vehicle.plate,
        provider: order.provider.trade,
        cost: order.laborCost + order.cost + order.partsCost,
        state: order.state,
        service: order.service.service,
        unitControl: order.service.unitControl,
        frequency: order.service.frequency,
        period: order.service.period,
      }));

      return formatOrders;
    } else {
      return [];
    }
  } else {
    const sanitizedOrders = orders.map((order: Order) => ({
      number: order.number,
      initDate: order.initDate,
      vehicle: order.vehicle.plate,
      provider: order.provider.trade,
      cost: order.laborCost + order.cost + order.partsCost,
      state: order.state,
      service: '',
      unitControl: '',
      frequency: '',
      period: '',
    }));

    return sanitizedOrders;
  }
};
