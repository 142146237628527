import { DeleteFilled } from '@ant-design/icons';
import { Col, Collapse, Popconfirm, Row, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { db } from '../../firebaseConfig';
import styled from 'styled-components';
import { Subtitle } from '../../components/PageSubtitle/styles';

const Value = styled.p`
  font-size: 16px;
  font-weight: 600;
`;

const { Panel } = Collapse;

const HistoricPart = ({ organization, purchases, setPurchases }) => {
  const handleDelete = async (id) => {
    await db
      .collection('organizations')
      .doc(organization)
      .collection('purchases')
      .doc(id)
      .delete()
      .catch((err) => alert(err));

    const filteredPurchases = purchases.filter((purchase) => purchase.id !== id);

    setPurchases(filteredPurchases);
  };

  const extra = (id) => (
    <Popconfirm
      title="Tem certeza que deseja deletar esta compra?"
      onConfirm={() => handleDelete(id)}
      okText="Sim"
      cancelText="Não"
    >
      <DeleteFilled style={{ color: '#6d6d6d' }} />
    </Popconfirm>
  );

  const header = (date) => (
    <Tag style={{ cursor: 'pointer' }} color="#1C6880">
      {moment(date).format('DD/MM/YY')}
    </Tag>
  );

  return (
    <div className="historic-container">
      <Subtitle>Histórico de compras</Subtitle>
      <p className="part-instruction">Verifique as compras que já foram realizadas deste item</p>
      <div style={{ overflowY: 'auto', height: 400 }}>
        {purchases && (
          <Collapse expandIconPosition="right">
            {purchases.map((purchase, index) => (
              <Panel header={header(purchase.date)} key={index + 1} extra={extra(purchase.id)}>
                <Row gutter={16}>
                  <Col span={6}>
                    <label className="detail-label">Fornecedor</label>
                    <Value>{purchase.provider.company}</Value>
                  </Col>
                  <Col span={6}>
                    <label className="detail-label">Quantidade</label>
                    <Value>{purchase.amount}</Value>
                  </Col>
                  <Col span={6}>
                    <label className="detail-label">Preço unitário</label>
                    <Value>R$ {purchase.unitPrice}</Value>
                  </Col>
                  <Col span={6}>
                    <label className="detail-label">Preço total</label>
                    <Value>R$ {purchase.unitPrice * purchase.amount}</Value>
                  </Col>
                  <Row>
                    <Col span={24}>
                      <div style={{ marginLeft: '0.5rem' }}>
                        <label className="detail-label">Descrição</label>
                        <p>{purchase.description}</p>
                      </div>
                    </Col>
                  </Row>
                </Row>
              </Panel>
            ))}
          </Collapse>
        )}
      </div>
    </div>
  );
};

export default HistoricPart;
