import {
  useGetVehicleByIdQuery,
  useInsertOdometerHistoryOneMutation,
  useUpdateVehicleMutation,
  Vehicle_Type_Enum,
} from '../../../generated/graphql';

export default function useVehicle(id: string) {
  const [, updateVehicleMutation] = useUpdateVehicleMutation();
  const [, insertOdometerHistoryOne] = useInsertOdometerHistoryOneMutation();

  const [{ data }] = useGetVehicleByIdQuery({
    variables: {
      id,
    },
  });

  const vehicle = data?.vehicle_by_pk;

  const handleUpdate = async (property: string, value: string) => {
    const response = await updateVehicleMutation({ id, obj: { [property]: value } });

    return response;
  };

  const handleCreateMeasure = async (value: number) => {
    if (vehicle?.type === Vehicle_Type_Enum.Equipment) {
      console.log(value);
    }

    const responseCreate = await insertOdometerHistoryOne({
      obj: {
        vehicle_id: id,
        current: value,
        measured_at: 'now()',
        previous: vehicle?.odometer_history?.current,
        source: 'manual',
      },
    });

    const odometerHistoryId = responseCreate.data?.insert_odometer_history_one?.id;

    const responseUpdateVehicle = await updateVehicleMutation({
      id,
      obj: { last_odometer_history_id: odometerHistoryId },
    });

    return responseUpdateVehicle;
  };

  return { vehicle, handleUpdate, handleCreateMeasure };
}
