import { DatePicker, Modal } from 'antd';
import pt_BR from 'antd/lib/locale-provider/pt_BR';
import moment from 'moment';
import React, { Component } from 'react';
import { db } from '../../firebaseConfig';

export default class CloseOrder extends Component {
  state = {
    finalDate: new Date(),
    organization: this.props.organization,
  };

  handleFinalize = async () => {
    const { organization } = this.props;

    const order = this.props.toFinalizeOrder;
    const services = this.props.toFinalizeOrder.services;

    if (order.maintenanceType === 'Corretiva') {
      await db
        .collection('organizations')
        .doc(organization)
        .collection('serviceOrders')
        .doc(order.id)
        .update({
          state: 'Fechada',
          finalDate: this.state.finalDate,
        })
        .catch((err) => console.log(err));
      this.props.onHide();
      this.setState({
        finalDate: '',
      });
      return;
    }

    const servicesWithInfo = services.map((service) => {
      return {
        ...service,
        date: this.state.finalDate,
        // POSSÍVEL CALL PARA VEÍCULOS E ATUALIZAR ODÔMETRO
      };
    });

    if (services !== undefined) {
      await db
        .collection('organizations')
        .doc(organization)
        .collection('serviceOrdersPreventive')
        .doc(order.id)
        .update({
          state: 'Fechada',
          finalDate: this.state.finalDate,
          services: servicesWithInfo,
        })
        .catch((err) => console.log(err));

      services.forEach(async (service) => {
        await db
          .collection('organizations')
          .doc(organization)
          .collection('maintenancePlan')
          .doc(service.id)
          .update({
            odometer: service.odometer,
            horimeter: service.horimeter,
            date: this.state.finalDate,
          })
          .then(() => {
            this.setState({
              finalDate: '',
            });
          })
          .catch((e) => console.error('Error updating maintenance plan', e));
      });
      this.props.onHide();
    }
    return;
  };

  render() {
    const { show } = this.props;

    return (
      <Modal
        title="Fechar ordem de serviço"
        visible={show}
        onCancel={this.props.onHide}
        footer={null}
      >
        <div style={{ textAlign: 'center', marginTop: '1rem' }}>
          <p>Informe a data de fechamento da Ordem de Serviço:</p>
          <DatePicker
            allowClear={false}
            onChange={(date) => this.setState({ finalDate: date._d })}
            locale={pt_BR}
            format="DD/MM/YYYY"
            defaultValue={moment(new Date(), 'DD/MM/YYYY')}
          />
        </div>

        <div style={{ marginTop: '2rem', textAlign: 'center' }}>
          <button onClick={this.handleFinalize} className="newOrder">
            Fechar ordem de serviço
          </button>
        </div>
      </Modal>
    );
  }
}
