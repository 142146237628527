import { Col, Row } from 'antd';
import React from 'react';
import { FaArrowLeft, FaTruck } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import Services from './components/Services';
import styled from 'styled-components';
import { Title } from '../../components/DetailPageTitle/styles';
import { Subtitle } from '../../components/DetailPageTitle/styles';
import EditableInfo from './components/EditableInfo/EditableInfo';
import useVehicle from './hooks/useVehicle';
import { Vehicle_Type_Enum } from '../../generated/graphql';
import vehicleTypes from './helpers/get-vehicle-type';

const Info = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

export default function DetailVehicle() {
  const history = useHistory();
  const { id } = useParams() as any;

  const { vehicle, handleUpdate, handleCreateMeasure } = useVehicle(id);

  if (!vehicle) return <p>Carregando...</p>;

  return (
    <div>
      <div className="arrow-left">
        <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <FaArrowLeft style={{ fontSize: '2em', color: '#AAA' }} />
        </span>
      </div>
      <Title>Detalhes do veículo</Title>
      <Row gutter={20}>
        <Col span={8}>
          <Subtitle>
            <FaTruck style={{ marginRight: '0.5rem' }} />
            Informações gerais
          </Subtitle>
          <div className="detail-info-container">
            <div className="detail-main-info">
              <label className="detail-label">Placa</label>
              <Info>{vehicle?.plate}</Info>
              <label className="detail-label">Tipo</label>
              <Info>{vehicleTypes[vehicle.type]}</Info>
              <label className="detail-label">
                {vehicle.type === Vehicle_Type_Enum.Equipment ? 'Horímetro' : 'Odômetro'}
              </label>
              <EditableInfo
                handleCreate={handleCreateMeasure}
                propertyValue={vehicle.odometer_history?.current.toFixed(2) ?? 0}
              />
            </div>
            <div className="secondary-info">
              <label className="detail-label">Marca</label>
              <EditableInfo
                property="brand"
                propertyValue={vehicle?.brand}
                handleUpdate={handleUpdate}
              />
              <label className="detail-label">Modelo</label>
              <EditableInfo
                property="model"
                propertyValue={vehicle?.model}
                handleUpdate={handleUpdate}
              />
              <label className="detail-label">Ano</label>
              <EditableInfo
                property="year"
                propertyValue={vehicle?.year}
                handleUpdate={handleUpdate}
              />
            </div>
          </div>
        </Col>
      </Row>
      {vehicle && <Services vehicle={vehicle} />}
    </div>
  );
}
