import { Col, Row, Table, Transfer } from 'antd';
import difference from 'lodash/difference';
import React from 'react';
import { CATEGORIES } from '../../constants';
import { Headline } from '../../components/Headline';

// Customize Table Transfer
const TableTransfer = ({ leftColumns, ...restProps }) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = leftColumns;

      const rowSelection = {
        getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? 'none' : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const leftTableColumns = [
  {
    dataIndex: 'service',
    title: 'Serviço',
  },
  {
    dataIndex: 'category',
    title: 'Categoria',
    filters: CATEGORIES.map((category) => ({ text: category, value: category })),
    onFilter: (value, record) => {
      return record.category.indexOf(value) === 0;
    },
  },
  {
    dataIndex: 'unitControl',
    title: 'Un. Controle',
  },
  {
    dataIndex: 'frequency',
    title: 'Frequência',
  },
  {
    dataIndex: 'period',
    title: 'Periodicidade (dias)',
  },
];

class ServicesTable extends React.Component {
  state = {
    disabled: false,
    showSearch: true,
  };

  onChange = (nextTargetKeys, direction, moveKeys) => {
    this.props.handleTargetServices(nextTargetKeys);
  };

  render() {
    const { disabled, showSearch } = this.state;
    return (
      <div style={{ marginTop: '1rem' }}>
        <Row gutter={48}>
          <Col span={12}>
            <Headline style={{ marginBottom: '1rem' }}>Serviços pendentes</Headline>
          </Col>
          <Col span={12}>
            <Headline style={{ marginBottom: '1rem' }}>Serviços a realizar</Headline>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TableTransfer
              dataSource={this.props.pendingServices}
              targetKeys={this.props.targetServices}
              listStyle={{
                width: 300,
              }}
              disabled={disabled}
              showSearch={showSearch}
              onChange={this.onChange}
              filterOption={(inputValue, item) => item.service.indexOf(inputValue) !== -1}
              leftColumns={leftTableColumns}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ServicesTable;
