import {
  HomeFilled,
  CarFilled,
  ContainerOutlined,
  BankOutlined,
  BarChartOutlined,
  BarsOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const ContainerItem = styled.div`
  display: flex;
  align-items: center;
`;

export default function Sidebar() {
  const router = useLocation();

  const [collapsed, setCollapsed] = useState(false);

  const pathname = router.pathname;

  return (
    <Layout.Sider data-testid="menu" collapsible collapsed={collapsed} onCollapse={setCollapsed}>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['/']} selectedKeys={[pathname]}>
        <Menu.Item key="/">
          <Link to="/">
            <ContainerItem>
              <HomeFilled />
              <span>Página inicial</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard">
          <Link to="/dashboard">
            <ContainerItem>
              <BarChartOutlined />
              <span>Dashboard</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
        <Menu.Item key="/service-orders">
          <Link to="/service-orders">
            <ContainerItem>
              <BarsOutlined />
              <span>Ordens de Serviço</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
        <Menu.Item key="/parts">
          <Link to="/parts">
            <ContainerItem>
              <ContainerOutlined />
              <span>Peças e Estoque</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
        <Menu.Item key="/vehicles-and-equipments">
          <Link to="/vehicles-and-equipments">
            <ContainerItem>
              <CarFilled />
              <span>Ativos</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
        <Menu.Item key="/plans">
          <Link to="/plans">
            <ContainerItem>
              <FileOutlined />
              <span>Planos de Manutenção</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
        <Menu.Item key="/providers">
          <Link to="/providers">
            <ContainerItem>
              <BankOutlined />
              <span>Fornecedores</span>
            </ContainerItem>
          </Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
}
