import styled from 'styled-components';

export const AddButton = styled.button`
  background-color: rgb(50, 182, 80);
  border: 1px solid rgb(46, 165, 73);
  border-radius: 4px;
  color: #fff;
  padding: 8px;
  width: 100px;
  font-size: 12px;
  margin-bottom: 1rem;
  cursor: pointer;
`;
