import { useState, useEffect } from 'react';
import { db } from '../../../firebaseConfig';

export const usePlans = (organization) => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    db.collection('organizations')
      .doc(organization)
      .collection('maintenanceTemplate')
      .onSnapshot((querySnapshot) => {
        const plans = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setPlans(plans);
        setLoading(false);
      });
  }, [organization]);

  return {
    plans,
    loading,
  };
};
