import currency from 'currency.js';
import { useCallback, useMemo } from 'react';

const defaultCurrencyOptions = {
  symbol: 'R$ ',
  precision: 2,
  decimal: ',',
  separator: '.',
  showSymbol: true,
};

export default function useCurrencyInput(props) {
  const { value, onChange, onBlur, clearable = true, currencyOptions = {} } = props;

  const unmaskValue = useCallback((maskedValue = '') => {
    if (maskedValue === '') return '';
    return parseInt(maskedValue.replace(/\D/g, '') || 0, 10) / 100;
  }, []);

  const maskValue = useCallback(
    (value = 0) => {
      let config = {
        ...defaultCurrencyOptions,
        ...currencyOptions,
      };

      return currency(value, config).format(config.showSymbol);
    },
    [currencyOptions],
  );

  const formatValue = useCallback(
    value => {
      if (typeof value === 'undefined' || (clearable && (value === '' || value === null))) {
        return '';
      }

      if (Number.isNaN(value)) {
        return value;
      }

      return maskValue(Number(value));
    },
    [maskValue, clearable],
  );

  const handleChange = useCallback(
    event => {
      const plainValue = unmaskValue(event.target.value);
      if (typeof onChange === 'function') onChange(plainValue);
    },
    [onChange, unmaskValue],
  );

  const handleBlur = useCallback(
    event => {
      const plainValue = unmaskValue(event.target.value);
      if (typeof onBlur === 'function') onBlur(plainValue);
    },
    [onBlur, unmaskValue],
  );

  const formatedValue = useMemo(() => formatValue(value), [value, formatValue]);

  return {
    handleBlur,
    handleChange,
    formatedValue,
  };
}
