import { QuestionCircleFilled } from '@ant-design/icons';
import { Popconfirm, Table } from 'antd';
import { FieldArray, Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaTrash } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Title } from '../../components/DetailPageTitle/styles';
import { Headline } from '../../components/Headline';
import { db } from '../../firebaseConfig';
import ServiceForm, { ServiceSchema } from './ServiceForm';
import { CustomSubmitButton, SectionContainer, SubmitContainer } from './styles';

const { Column } = Table;

const PlanSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  services: Yup.array()
    .of(ServiceSchema)
    .required()
    .min(1, 'É preciso inserir pelo menos um serviço'),
});

const usePlan = (organization, id) => {
  const [plan, setPlan] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!document || !id) {
      setLoading(false);
      return;
    }

    db.collection('organizations')
      .doc(organization)
      .collection('maintenanceTemplate')
      .doc(id)
      .onSnapshot((doc) => {
        const plan = {
          ...doc.data(),
          id: doc.id,
        };
        setLoading(false);
        setPlan(plan);
      });
  }, [organization, id]);

  return {
    plan,
    loading,
  };
};

const addPlan = async (organization, plan) => {
  await db
    .collection('organizations')
    .doc(organization)
    .collection('maintenanceTemplate')
    .add(plan);
};

const updatePlan = async (organization, id, plan) => {
  await db
    .collection('organizations')
    .doc(organization)
    .collection('maintenanceTemplate')
    .doc(id)
    .set(plan);
};

const PlanForm = ({ organization }) => {
  const history = useHistory();
  const { id } = useParams();

  const {
    plan = {
      name: '',
      services: [],
    },
    loading,
  } = usePlan(organization, id);

  const handleCreate = async (values, { resetForm }) => {
    await addPlan(organization, values);
    resetForm();
    history.push('/plans');
  };

  const handleUpdate = async (values, { resetForm, setSubmitting }) => {
    await updatePlan(organization, id, values);
    resetForm();
    setSubmitting();
    history.push('/plans');
  };

  const handleSubmit = id ? handleUpdate : handleCreate;

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="arrow-left">
            <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
              <FaArrowLeft style={{ fontSize: '2em', color: '#AAA' }} />
            </span>
          </div>
          <Title>{id ? 'Editar um Plano de Manutenção' : 'Inserir novo Plano de Manutenção'}</Title>
          <Formik
            enableReinitialize
            initialValues={plan}
            onSubmit={handleSubmit}
            validationSchema={PlanSchema}
          >
            {() => (
              <>
                <Form>
                  <div style={{ margin: '0 auto' }}>
                    <SectionContainer>
                      <Headline>Plano de Manutenção</Headline>
                      <Form.Item name="name">
                        <Input name="name" placeholder="Nome do plano"></Input>
                      </Form.Item>
                    </SectionContainer>
                    <SectionContainer>
                      <Headline>Serviços</Headline>
                      <Form.Item name="services">
                        <FieldArray name="services">
                          {({ push, remove, form }) => (
                            <>
                              <ServiceForm addService={(service) => push(service)} />

                              <Table dataSource={form.values.services} pagination={false}>
                                <Column title="Serviço" key="service" dataIndex="service" />
                                <Column title="Categoria" key="category" dataIndex="category" />
                                <Column
                                  title="Unidade de Controle"
                                  key="unitControl"
                                  dataIndex="unitControl"
                                />
                                <Column title="Frequência" key="frequency" dataIndex="frequency" />
                                <Column
                                  title="Priodicidade (dias)"
                                  key="period"
                                  dataIndex="period"
                                />
                                <Column
                                  title="Ações"
                                  key="actions"
                                  align="right"
                                  render={() => (
                                    <Popconfirm
                                      title="Tem certeza?"
                                      icon={<QuestionCircleFilled style={{ color: 'red' }} />}
                                      onConfirm={remove}
                                    >
                                      <FaTrash style={{ cursor: 'pointer' }} />
                                    </Popconfirm>
                                  )}
                                />
                              </Table>
                            </>
                          )}
                        </FieldArray>
                      </Form.Item>
                    </SectionContainer>
                    <SubmitContainer>
                      <Form.Item name="submit">
                        <CustomSubmitButton type="primary">Enviar</CustomSubmitButton>
                      </Form.Item>
                    </SubmitContainer>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default PlanForm;
