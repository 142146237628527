import { Col, DatePicker, Popconfirm, Row, Table, Tag, Tooltip } from 'antd';
import locale from 'antd/lib/locale-provider/pt_BR';
import { uniqBy } from 'lodash';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import ReactExport from 'react-export-excel';
import { FaCheck, FaExclamationCircle, FaFileExcel, FaPen, FaPrint, FaTrash } from 'react-icons/fa';
import { PrintComponents } from 'react-print-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ContainerTitle, Title } from '../../components/PageTitle/styles';
import { db } from '../../firebaseConfig';
import CloseOrder from './CloseOrder';
import { PrintableServiceOrder } from './components/PrintableServiceOrder';
import { getExcelOrderParts } from './excel-export/get-part-orders';
import { getExcelOrderServices } from './excel-export/get-service-orders';
import { formatDateBRL } from './helpers/format-date';
import { Subtitle } from '../../components/PageSubtitle/styles';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { RangePicker } = DatePicker;

const ExportButton = styled.button`
  background-color: #fff;
  margin-left: 1rem;
  font-size: 0.85rem;
  height: 2rem;
  border: 1px solid rgb(46, 165, 73);
  border-radius: 4px;
  color: rgb(50, 182, 80);
`;

export default class ServiceOrders extends Component {
  state = {
    serviceOrders: [],
    serviceOrdersPreventive: [],
    vehicles: [],
    providers: [],
    user: this.props.user,
    showFinalize: false,
    loading: true,
    finalizeOrder: undefined,
    serviceOrdersFilter: {
      startDate: moment().subtract(1, 'month').startOf('day'),
      finishDate: moment().endOf('day'),
    },
    serviceOrdersPreventiveFilter: {
      startDate: moment().subtract(1, 'month').startOf('day'),
      finishDate: moment().endOf('day'),
    },
  };

  componentDidMount = async () => {
    const { organization } = this.props;

    await db
      .collection('organizations')
      .doc(organization)
      .collection('serviceOrders')
      .onSnapshot((querySnapshot) => {
        const serviceOrders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          initDate: doc.data().initDate ? doc.data().initDate.toDate() : null,
          finalDate: doc.data().finalDate ? doc.data().finalDate.toDate() : null,
        }));
        const sortedOrders = serviceOrders.sort(({ initDate: a }, { initDate: b }) => b - a);
        this.setState({ serviceOrders: sortedOrders });
      });

    await db
      .collection('organizations')
      .doc(organization)
      .collection('serviceOrdersPreventive')
      .onSnapshot((querySnapshot) => {
        const serviceOrdersPreventive = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          initDate: doc.data().initDate ? doc.data().initDate.toDate() : null,
          finalDate: doc.data().finalDate ? doc.data().finalDate.toDate() : null,
        }));
        const sortedOrders = serviceOrdersPreventive.sort(
          ({ initDate: a }, { initDate: b }) => b - a,
        );
        this.setState({
          serviceOrdersPreventive: sortedOrders,
        });
      });

    await db
      .collection('organizations')
      .doc(organization)
      .collection('providers')
      .onSnapshot((querySnapshot) => {
        const providers = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        this.setState({ providers, loading: false });
      });
  };

  deleteRecord(id, type) {
    const { organization } = this.props;

    if (type === 'preventive') {
      db.collection('organizations')
        .doc(organization)
        .collection('serviceOrdersPreventive')
        .doc(id)
        .delete()
        .catch((err) => console.log(err));
    } else {
      db.collection('organizations')
        .doc(organization)
        .collection('serviceOrders')
        .doc(id)
        .delete()
        .catch((err) => console.log(err));
    }
  }

  handleCloseFinalize = () => {
    this.setState({ showFinalize: false });
  };

  render() {
    const {
      serviceOrders,
      serviceOrdersPreventive,
      showFinalize,
      loading,
      finalizeOrder,
      serviceOrdersFilter,
      serviceOrdersPreventiveFilter,
    } = this.state;

    const formatOrders = serviceOrders.map((order, index) => ({
      ...order,
      key: index + 1,
    }));

    const filteredCorrective = formatOrders.filter(
      ({ initDate }) =>
        initDate >= serviceOrdersFilter.startDate && initDate <= serviceOrdersFilter.finishDate,
    );

    const excelCorrectivesData = getExcelOrderParts(filteredCorrective);

    const formatOrdersPreventive = serviceOrdersPreventive.map((order, index) => ({
      ...order,
      key: index + 1,
    }));

    const filteredPreventive = formatOrdersPreventive.filter(
      ({ initDate }) =>
        initDate >= serviceOrdersFilter.startDate && initDate <= serviceOrdersFilter.finishDate,
    );

    const excelPreventivePartsData = getExcelOrderParts(filteredCorrective);
    const excelPreventiveServicesData = getExcelOrderServices(filteredPreventive);

    const correctiveVehicles = uniqBy(
      serviceOrders.map((order) => ({
        text: order.vehicle.plate,
        value: order.vehicle.plate,
      })),
      'text',
    );

    const correctiveProviders = uniqBy(
      serviceOrders.map((order) => ({
        text: order.provider.trade,
        value: order.provider.trade,
      })),
      'text',
    );

    const preventiveVehicles = uniqBy(
      serviceOrdersPreventive.map((order) => ({
        text: order.vehicle.plate,
        value: order.vehicle.plate,
      })),
      'text',
    );

    const preventiveProviders = uniqBy(
      serviceOrdersPreventive.map((order) => ({
        text: order.provider.trade,
        value: order.provider.trade,
      })),
      'text',
    );

    const columnsTable = [
      {
        title: '#',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: 'Nº',
        dataIndex: 'number',
        key: 'number',
      },
      {
        title: 'Criada em',
        dataIndex: 'initDate',
        key: 'initDate',
        render: (value, record) => {
          let alert = '';

          const initial = moment(record?.initDate);
          const final = moment(record?.finalDate);
          const difference = final.diff(initial, 'days');

          if (difference < 0) {
            alert = (
              <Tooltip title="Data de término da ordem não está consistente">
                <FaExclamationCircle style={{ color: 'red' }} />
              </Tooltip>
            );
          }

          return (
            <div>
              {formatDateBRL(value)} {alert}
            </div>
          );
        },
      },
      {
        title: 'Veículo',
        render: (text, record) => record.vehicle.plate,
        filters: correctiveVehicles,
        onFilter: (value, record) => record.vehicle.plate.indexOf(value) === 0,
        sorter: (a, b) => a.vehicle.plate.length - b.vehicle.plate.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Fornecedor',
        render: (text, record) => record.provider.trade,
        filters: correctiveProviders,
        onFilter: (value, record) => record.provider.trade.indexOf(value) === 0,
        sorter: (a, b) => a.provider.trade.length - b.provider.trade.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Custo (R$)',
        dataIndex: 'cost',
        key: 'cost',
        render: (_, record) =>
          ((record.laborCost || 0) + (record.cost || 0) + (record.partsCost || 0)).toFixed(2),
        sorter: (a, b) => a.cost - b.cost,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Estado',
        dataIndex: 'state',
        key: 'state',
        render: (tag) =>
          tag === 'Fechada' ? (
            <Tag color="#87d068">Fechada</Tag>
          ) : (
            <Tag color="#CCC">
              <span style={{ color: 'black' }}>Aberta</span>
            </Tag>
          ),
        filters: [
          { text: 'Aberta', value: 'Aberta' },
          { text: 'Fechada', value: 'Fechada' },
        ],
        onFilter: (value, record) => record.state.indexOf(value) === 0,
        sorter: (a, b) => a.state.length - b.state.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Ações',
        key: 'action',
        render: (text, record) => (
          <>
            {record.state === 'Aberta' ? (
              <FaCheck
                style={{ cursor: 'pointer', marginRight: 12 }}
                onClick={() => {
                  this.setState({ finalizeOrder: record, showFinalize: true });
                }}
              />
            ) : (
              ''
            )}
            <Link to={`/service-orders/${record.id}`}>
              <FaPen style={{ cursor: 'pointer', marginRight: 12, color: '#505659' }} />
            </Link>
            <Popconfirm
              title="Tem certeza que deseja deletar esta ordem de serviço?"
              onConfirm={() => this.deleteRecord(record.id, 'corrective')}
            >
              <FaTrash style={{ cursor: 'pointer', marginRight: 12 }} />
            </Popconfirm>
            <PrintComponents trigger={<FaPrint style={{ cursor: 'pointer' }} />}>
              <PrintableServiceOrder record={record} />
            </PrintComponents>
          </>
        ),
      },
    ];

    const columnsTablePreventive = [
      {
        title: '#',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: 'Nº',
        dataIndex: 'number',
        key: 'number',
      },
      {
        title: 'Criada em',
        dataIndex: 'initDate',
        key: 'initDate',
        render: (value, record) => {
          let alert = '';

          const initial = moment(record?.initDate);
          const final = moment(record?.finalDate);
          const difference = final.diff(initial, 'days');

          console.log(difference);

          if (difference < 0) {
            alert = (
              <Tooltip title="Data de término da ordem não está consistente">
                <FaExclamationCircle style={{ color: 'red' }} />
              </Tooltip>
            );
          }

          return (
            <div>
              {formatDateBRL(value)} {alert}
            </div>
          );
        },
      },
      {
        title: 'Veículo',
        render: (text, record) => record.vehicle.plate,
        filters: preventiveVehicles,
        onFilter: (value, record) => record.vehicle.plate.indexOf(value) === 0,
        sorter: (a, b) => a.vehicle.plate.length - b.vehicle.plate.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Fornecedor',
        render: (text, record) => record.provider.trade,

        filters: preventiveProviders,
        onFilter: (value, record) => record.provider.trade.indexOf(value) === 0,
        sorter: (a, b) => a.provider.trade.length - b.provider.trade.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Custo (R$)',
        dataIndex: 'cost',
        key: 'cost',
        render: (_, record) =>
          ((record.laborCost || 0) + (record.cost || 0) + (record.partsCost || 0)).toFixed(2),
        sorter: (a, b) => a.cost - b.cost,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Estado',
        dataIndex: 'state',
        key: 'state',
        render: (tag) =>
          tag === 'Fechada' ? (
            <Tag color="#87d068">Fechada</Tag>
          ) : (
            <Tag color="#CCC">
              <span style={{ color: 'black' }}>Aberta</span>
            </Tag>
          ),
        filters: [
          { text: 'Aberta', value: 'Aberta' },
          { text: 'Fechada', value: 'Fechada' },
        ],
        onFilter: (value, record) => record.state.indexOf(value) === 0,
        sorter: (a, b) => a.state.length - b.state.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Ações',
        key: 'action',
        render: (text, record) => (
          <Fragment>
            {record.state === 'Aberta' ? (
              <FaCheck
                style={{ cursor: 'pointer', marginRight: 12 }}
                onClick={() => {
                  this.setState({ finalizeOrder: record, showFinalize: true });
                }}
              />
            ) : (
              ''
            )}
            <Link
              to={{
                pathname: `/service-orders/${record.id}`,
              }}
            >
              <FaPen style={{ cursor: 'pointer', marginRight: 12, color: '#505659' }} />
            </Link>
            <Popconfirm
              title="Tem certeza que deseja deletar esta ordem de serviço?"
              onConfirm={() => this.deleteRecord(record.id, 'preventive')}
            >
              <FaTrash style={{ cursor: 'pointer', marginRight: 12 }} />
            </Popconfirm>
            <PrintComponents trigger={<FaPrint style={{ cursor: 'pointer' }} />}>
              <PrintableServiceOrder record={record} />
            </PrintComponents>
          </Fragment>
        ),
      },
    ];

    return (
      <div>
        <ContainerTitle>
          <Title>Ordens de Serviço</Title>
          <Link
            to={{
              pathname: `/service-orders/new/`,
            }}
          >
            <button className="newOrder">Nova ordem</button>
          </Link>
        </ContainerTitle>
        <div style={{ marginBottom: '1rem' }}>
          <Subtitle>Corretivas</Subtitle>
          <p
            style={{
              fontSize: '1rem',
              color: 'gray',
            }}
          >
            Cadastre as ordens de serviço de manutenções <i>corretivas</i> e selecione o período de
            visualização.
          </p>

          <RangePicker
            allowClear={false}
            locale={locale}
            value={Object.values(serviceOrdersFilter)}
            onChange={([startDate, finishDate]) => {
              this.setState({
                serviceOrdersFilter: {
                  startDate: startDate.startOf('day'),
                  finishDate: finishDate.endOf('day'),
                },
              });
            }}
          />
          <ExcelFile
            fileExtension="xlsx"
            filename={`Infleet | Manutenção - Ordens de Serviço Corretivas`}
            element={
              <ExportButton type="link" shape="round" icon="download">
                <FaFileExcel /> Exportar planilha
              </ExportButton>
            }
          >
            <ExcelSheet data={excelCorrectivesData} name="Peças">
              <ExcelColumn label="Número" value="number" />
              <ExcelColumn label="Criada em" value={(val) => formatDateBRL(val.initDate)} />
              <ExcelColumn label="Placa do veículo" value="vehicle" />
              <ExcelColumn label="Fornecedor" value="provider" />
              <ExcelColumn label="Custo total(R$)" value="cost" />
              <ExcelColumn label="Estado" value="state" />
              <ExcelColumn label="Nome da peça" value="part" />
              <ExcelColumn label="Categoria" value="partCategory" />
              <ExcelColumn label="Unidade de controle" value="partUnitControl" />
              <ExcelColumn label="Preço unitário" value="partMeanUnitPrice" />
              <ExcelColumn label="Quantidade de peças" value="partAmount" />
              <ExcelColumn label="Custo das peças" value="partCost" />
            </ExcelSheet>
          </ExcelFile>
        </div>
        <Row>
          <Col style={{ height: '50vh' }} span={24}>
            <Table
              pagination={false}
              dataSource={filteredCorrective}
              columns={columnsTable}
              loading={loading}
              scroll={{ y: 350 }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <div style={{ marginBottom: '1rem' }}>
            <Subtitle>Preventivas</Subtitle>
            <p
              style={{
                fontSize: '1rem',
                color: 'gray',
              }}
            >
              Cadastre as ordens de serviço de manutenções <i>preventivas</i> e selecione o período
              de visualização.
            </p>
            <RangePicker
              allowClear={false}
              locale={locale}
              value={Object.values(serviceOrdersPreventiveFilter)}
              onChange={([startDate, finishDate]) => {
                this.setState({
                  serviceOrdersPreventiveFilter: {
                    startDate,
                    finishDate,
                  },
                });
              }}
            />
            <ExcelFile
              fileExtension="xlsx"
              filename={`Infleet | Manutenção - Ordens de Serviço Preventivas`}
              element={
                <ExportButton type="link" shape="round" icon="download">
                  <FaFileExcel /> Exportar planilha
                </ExportButton>
              }
            >
              <ExcelSheet data={excelPreventivePartsData} name="Peças">
                <ExcelColumn label="Número" value="number" />
                <ExcelColumn label="Criada em" value={(val) => formatDateBRL(val.initDate)} />
                <ExcelColumn label="Placa do veículo" value="vehicle" />
                <ExcelColumn label="Fornecedor" value="provider" />
                <ExcelColumn label="Custo total(R$)" value="cost" />
                <ExcelColumn label="Estado" value="state" />
                <ExcelColumn label="Nome da peça" value="part" />
                <ExcelColumn label="Categoria" value="partCategory" />
                <ExcelColumn label="Unidade de controle" value="partUnitControl" />
                <ExcelColumn label="Preço unitário" value="partMeanUnitPrice" />
                <ExcelColumn label="Quantidade de peças" value="partAmount" />
                <ExcelColumn label="Custo das peças" value="partCost" />
              </ExcelSheet>
              <ExcelSheet data={excelPreventiveServicesData} name="Serviços">
                <ExcelColumn label="Número" value="number" />
                <ExcelColumn label="Criada em" value={(val) => formatDateBRL(val.initDate)} />
                <ExcelColumn label="Placa do veículo" value="vehicle" />
                <ExcelColumn label="Fornecedor" value="provider" />
                <ExcelColumn label="Custo total(R$)" value="cost" />
                <ExcelColumn label="Estado" value="state" />
                <ExcelColumn label="Nome do Serviço" value="service" />
                <ExcelColumn label="Unidade de Controle" value="unitControl" />
                <ExcelColumn label="Frequência" value="frequency" />
                <ExcelColumn label="Periodicidade (dias)" value="period" />
              </ExcelSheet>
            </ExcelFile>
          </div>
          <br />
          <Col style={{ height: '50vh' }} span={24}>
            <Table
              pagination={false}
              dataSource={formatOrdersPreventive.filter(
                ({ initDate }) =>
                  initDate >= serviceOrdersPreventiveFilter.startDate &&
                  initDate <= serviceOrdersPreventiveFilter.finishDate,
              )}
              columns={columnsTablePreventive}
              loading={loading}
              scroll={{ y: 500 }}
            />
          </Col>
        </Row>
        <CloseOrder
          toFinalizeOrder={finalizeOrder}
          organization={this.props.organization}
          show={showFinalize}
          onHide={this.handleCloseFinalize}
        />
      </div>
    );
  }
}
