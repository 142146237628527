import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthProvider';
import Providers from './Providers';

export interface IAppProps {}

export default function PartsWrapper(props: IAppProps) {
  const { firebaseOrganizationId } = useContext(AuthContext);

  if (!firebaseOrganizationId) return <span>Carregando...</span>;

  return (
    <div>
      <Providers organization={firebaseOrganizationId} />
    </div>
  );
}
