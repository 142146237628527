import { Col, Input, Modal, Row } from 'antd';
import 'antd/dist/antd.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import * as Yup from 'yup';
import { db } from '../../firebaseConfig';
import { SubmitButton } from './styles';

export default class EditProvider extends Component {
  updateToFirebase = async (values) => {
    const { organization } = this.props;
    const { id, ...rest } = values;
    await db
      .collection('organizations')
      .doc(organization)
      .collection('providers')
      .doc(id)
      .set(rest);
  };

  render() {
    const { visible, handleClose, provider } = this.props;

    const ProviderSchema = Yup.object().shape({
      CNPJ: Yup.number().typeError('Apenas números são permitidos').required('Campo obrigatório'),
      address: Yup.string().required('Campo obrigatório'),
      company: Yup.string().required('Campo obrigatório'),
      trade: Yup.string().required('Campo obrigatório'),
    });

    return (
      <div>
        <Formik
          onSubmit={(values, { resetForm, setSubmitting }) => {
            const toUpdateValues = {
              ...values,
              CNPJ: parseInt(values.CNPJ, 10),
              trade: values.trade.toUpperCase(),
              company: values.company.toUpperCase(),
            };
            this.updateToFirebase(toUpdateValues)
              .then(() => {
                setSubmitting(false);
                handleClose();
                resetForm({});
              })
              .catch((err) => {
                console.log(err);
                setSubmitting(false);
                resetForm({});
              });
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={ProviderSchema}
          initialValues={provider}
          render={({ handleSubmit }) => (
            <Form>
              <Modal
                visible={visible}
                onCancel={handleClose}
                title="Editar fornecedor"
                footer={
                  <div>
                    <SubmitButton onClick={handleSubmit}>Adicionar</SubmitButton>
                  </div>
                }
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <label className="label-form" htmlFor="company">
                      Razão social:
                      <ErrorMessage name="company">
                        {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                      </ErrorMessage>
                    </label>
                    <Field
                      name="company"
                      render={({ field /* _form */ }) => (
                        <Input {...field} placeholder="Ex.: Continental Pneus LTDA" />
                      )}
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={16}>
                  <Col span={12}>
                    <label className="label-form" htmlFor="trade">
                      Nome fantasia:
                      <ErrorMessage name="trade">
                        {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                      </ErrorMessage>
                    </label>
                    <Field
                      name="trade"
                      render={({ field /* _form */ }) => (
                        <Input {...field} placeholder="Ex.:Continental" />
                      )}
                    />
                  </Col>
                  <Col span={12}>
                    <label className="label-form" htmlFor="CNPJ">
                      CNPJ:
                      <ErrorMessage name="CNPJ">
                        {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                      </ErrorMessage>
                    </label>
                    <Field
                      name="CNPJ"
                      render={({ field /* _form */ }) => (
                        <Input {...field} placeholder="Ex.:11111111111111" />
                      )}
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={16}>
                  <Col span={24}>
                    <label className="label-form" htmlFor="address">
                      Endereço:
                      <ErrorMessage name="address">
                        {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                      </ErrorMessage>
                    </label>
                    <Field
                      name="address"
                      render={({ field /* _form */ }) => (
                        <Input {...field} placeholder="Ex.: Rua João Gomes 751, 4584120, Bloco 2" />
                      )}
                    />
                  </Col>
                </Row>
              </Modal>
            </Form>
          )}
        />
      </div>
    );
  }
}
