import { Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { ContainerTitle, Title } from '../../components/PageTitle/styles';
import { db } from '../../firebaseConfig';
import { Container } from './components/Container';
import Ranking from './components/Ranking/Ranking';
import CostChart from './CostChart';
import MeanCost from './MeanCost';
import MeanTime from './MeanTime';
import TotalCost from './TotalCost';

const { RangePicker } = DatePicker;

class Dashboard extends Component {
  state = {
    date: [moment(new Date()).subtract(3, 'months'), moment(new Date())],
    serviceOrders: [],
    serviceOrdersPreventive: [],
    loading: true,
  };

  componentDidMount = async () => {
    this.fetchServiceOrders();
  };

  fetchServiceOrders = async () => {
    const { organization } = this.props;
    const { date } = this.state;
    const orgRef = db.collection('organizations').doc(organization);

    const startDate = date[0].startOf('day').toDate();
    const endDate = date[1].endOf('day').toDate();

    await orgRef
      .collection('serviceOrders')
      .where('initDate', '>=', startDate)
      .where('initDate', '<=', endDate)
      .orderBy('initDate', 'desc')
      .onSnapshot(async (snapshot) => {
        let serviceOrders = await snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            parts: doc.data().parts ? doc.data().parts : [],
            initDate: doc.data().initDate ? doc.data().initDate.toDate() : 0,
            finalDate: doc.data().finalDate ? doc.data().finalDate.toDate() : 0,
          };
        });
        this.setState({ serviceOrders });
      });

    await orgRef
      .collection('serviceOrdersPreventive')
      .where('initDate', '>=', startDate)
      .where('initDate', '<=', endDate)
      .orderBy('initDate', 'desc')
      .onSnapshot(async (snapshot) => {
        let serviceOrdersPreventive = await snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            initDate: doc.data().initDate ? doc.data().initDate.toDate() : 0,
            finalDate: doc.data().finalDate ? doc.data().finalDate.toDate() : 0,
          };
        });
        this.setState({ serviceOrdersPreventive, loading: false });
      });
  };

  render() {
    const { date, serviceOrders, serviceOrdersPreventive, loading } = this.state;

    console.log('corrective', serviceOrders);

    console.log('preventive', serviceOrdersPreventive);

    return (
      <div>
        <ContainerTitle>
          <Title>Dashboard</Title>
        </ContainerTitle>
        <Row>
          <p className="instruction">Selecione o período de análise</p>
        </Row>
        <Row>
          <div className="rangepicker">
            <RangePicker
              onChange={(date) => this.setState({ date })}
              defaultValue={date}
              value={date}
              format="DD/MM/YYYY"
              separator="-"
              showOk={true}
              renderExtraFooter={() => {
                return (
                  <div>
                    <button
                      className="date-footer__button"
                      onClick={() =>
                        this.setState({
                          date: [moment(new Date()).subtract(6, 'months'), moment(new Date())],
                        })
                      }
                    >
                      Últimos 6 meses
                    </button>
                    <button
                      className="date-footer__button"
                      onClick={() =>
                        this.setState({
                          date: [moment(new Date()).subtract(12, 'months'), moment(new Date())],
                        })
                      }
                    >
                      Últimos 12 meses
                    </button>
                  </div>
                );
              }}
            />
            <button
              onClick={() => {
                this.fetchServiceOrders();
              }}
              className="date-footer__button--OK"
            >
              Visualizar
            </button>
          </div>
        </Row>
        <br />
        <Row gutter={16}>
          <TotalCost
            loading={loading}
            serviceOrders={serviceOrders}
            serviceOrdersPreventive={serviceOrdersPreventive}
          />
          <MeanCost
            loading={loading}
            serviceOrders={serviceOrders}
            serviceOrdersPreventive={serviceOrdersPreventive}
          />
          <MeanTime
            loading={loading}
            serviceOrders={serviceOrders}
            serviceOrdersPreventive={serviceOrdersPreventive}
          />
        </Row>
        <br />
        <Container>
          <Col span={14}>
            <CostChart
              serviceOrders={serviceOrders}
              serviceOrdersPreventive={serviceOrdersPreventive}
            />
          </Col>
          <Col span={10}>
            <Ranking
              serviceOrders={serviceOrders}
              serviceOrdersPreventive={serviceOrdersPreventive}
              date={date}
            />
          </Col>
        </Container>
      </div>
    );
  }
}

export default Dashboard;
