import { Col, notification, Row, Select } from 'antd';
import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Label } from '../../../../components/FormLabel';
import { CATEGORIES } from '../../../../constants';
import { db } from '../../../../firebaseConfig';
import { CustomField, CustomSelect, Error, FieldContainer, SubmitButton } from './styles';

const { Option } = Select;

const ServiceSchema = Yup.object().shape({
  service: Yup.string().required('Campo obrigatório'),
  category: Yup.string().required('Campo obrigatório'),
  unitControl: Yup.string().required('Campo obrigatório'),
  frequency: Yup.number().required('Campo obrigatório'),
  // period: Yup.number().required('Campo obrigatório'), removed to evaluate on production
});

export default function ServiceForm({
  organization,
  vehicle,
  editableService,
  closeModal,
  setServices,
}) {
  const handleSave = async (values) => {
    const maintenancePlanRef = db
      .collection('organizations')
      .doc(organization)
      .collection('maintenancePlan');

    try {
      if (editableService) {
        const newService = { ...editableService, ...values };
        await maintenancePlanRef.doc(editableService.id).update(values);

        setServices((oldList) => [
          newService,
          ...oldList.filter(({ id }) => editableService.id !== id),
        ]);
        closeModal();
      } else {
        await maintenancePlanRef.add({ ...values, vehicle });
        setServices((oldList) => [values, ...oldList]);
        closeModal();
      }
    } catch (e) {
      console.error(e);
      notification['error']({
        message: 'Erro ao salvar serviço',
        description: 'Contate o suporte',
      });
    }
  };

  return (
    <Formik
      initialValues={
        editableService ?? {
          service: '',
          category: '',
          unitControl: '',
          frequency: '',
          period: '',
        }
      }
      onSubmit={async (values, { setSubmitting }) => {
        await handleSave(values);
        setSubmitting(false);
      }}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={ServiceSchema}
    >
      {({ initialValues, errors, touched, setFieldValue }) => (
        <Form>
          <Row>
            <Col span={24}>
              <FieldContainer>
                <Label>Nome do serviço</Label>
                <CustomField
                  type="text"
                  name="service"
                  placeholder="Ex.: Troca de filtros"
                  error={errors.service}
                  touched={touched.service}
                  autoComplete="off"
                />
                <ErrorMessage name="service">{(msg) => <Error>* {msg}</Error>}</ErrorMessage>
              </FieldContainer>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FieldContainer>
                <Label>Categoria</Label>
                <CustomSelect
                  defaultValue={initialValues.category}
                  name="category"
                  error={errors.category}
                  touched={touched.category}
                  size="large"
                  onChange={(value) => setFieldValue('category', value)}
                >
                  {CATEGORIES.map((category, index) => (
                    <Option key={index} value={category}>
                      {category}
                    </Option>
                  ))}
                </CustomSelect>
                <ErrorMessage name="category">{(msg) => <Error>* {msg}</Error>}</ErrorMessage>
              </FieldContainer>
            </Col>
            <Col xs={12}>
              <FieldContainer>
                <Label>Unidade de Controle</Label>
                <CustomSelect
                  defaultValue={initialValues.unitControl}
                  name="unitControl"
                  error={errors.unitControl}
                  touched={touched.unitControl}
                  size="large"
                  onChange={(value) => setFieldValue('unitControl', value)}
                >
                  <Option value="H">H (horas)</Option>
                  <Option value="KM">KM (quilômetros)</Option>
                </CustomSelect>
                <ErrorMessage name="unitControl">{(msg) => <Error>* {msg}</Error>}</ErrorMessage>
              </FieldContainer>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12}>
              <FieldContainer>
                <Label>Frequência (km)</Label>
                <CustomField
                  type="number"
                  name="frequency"
                  placeholder="Ex.: 3000 km"
                  error={errors.frequency}
                  touched={touched.frequency}
                />
                <ErrorMessage name="frequency">{(msg) => <Error>* {msg}</Error>}</ErrorMessage>
              </FieldContainer>
            </Col>
            <Col xs={12}>
              <FieldContainer>
                <Label>Periodicidade em dias</Label>
                <CustomField
                  type="number"
                  name="period"
                  placeholder="Ex.: 120 dias"
                  error={errors.period}
                  touched={touched.period}
                />
                <ErrorMessage name="period">{(msg) => <Error>* {msg}</Error>}</ErrorMessage>
              </FieldContainer>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <SubmitButton type="submit">Salvar serviço</SubmitButton>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
