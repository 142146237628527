import { useState, useEffect } from 'react';
import { useSummaryData } from './useSummaryData';
import { uniqBy } from 'lodash';
import moment from 'moment';

export const useCalculateSummaryData = (organization, limitOdometer, limitHorimeter, limitDays) => {
  const { vehicles, pendingServices, correctiveOrders, preventiveOrders } = useSummaryData(
    organization,
  );

  const [pendentServices, setPendentServices] = useState([]);
  const [ongoingServices, setOngoingServices] = useState([]);
  const [urgentServices, setUrgentServices] = useState([]);
  const [finalizedServices, setFinalizedServices] = useState([]);

  useEffect(() => {
    const vehiclesByPlate = vehicles.reduce(
      (accumulator, vehicle) => ({
        ...accumulator,
        [vehicle.plate]: vehicle,
      }),
      {},
    );

    const setVehicle = (service) => ({
      ...service,
      vehicle: {
        ...vehiclesByPlate[service.vehicle?.plate],
      },
    });

    //CARREGANDO TODOS OS SERVIÇOS EXISTENTES
    const servicesPreventive = preventiveOrders
      .map((order) =>
        order.services.map(setVehicle).map((service) => ({
          ...service,
          state: order.state,
          hasPreventive: true,
        })),
      )
      .reduce((acc, val) => acc.concat(val), []);

    const servicesPlan = pendingServices.map(setVehicle).map((service) => ({
      ...service,
      hasPreventive: false,
      state: 'Fechada',
    }));

    const allServices = [...servicesPreventive, ...servicesPlan];

    const formatServices = allServices.map((service) => ({
      ...service,
      date: service.date ? service.date.toDate() : 0,
    }));

    const sortServices = formatServices.sort((a, b) => {
      var dateA = new Date(a.date);
      var dateB = new Date(b.date);
      return dateB - dateA;
    });
    // FIM - CARREGANDO SERVIÇOS EXISTENTES

    // ORDENS CORRETIVAS ABERTAS
    const openCorrectives = correctiveOrders.filter((order) => order.state === 'Aberta');
    // FIM - ORDENS CORRETIVAS ABERTAS

    // UNIFICANDO SERVIÇOS COLOCANDO CADA UM EM TRÊS LISTAS SEPARADAS
    const uniqueServices = uniqBy(sortServices, 'id');

    const pendentServices = [];
    const urgentServices = [];
    const ongoingServices = [...openCorrectives];

    uniqueServices.forEach((service) => {
      if (service.hasPreventive) {
        if (service.state === 'Aberta') {
          ongoingServices.push(service);
          return;
        }

        pendingServices.forEach((pendingService) => {
          if (service.id !== pendingService.id) {
            return;
          }

          const diffLastDate = service.date ? moment(new Date()).diff(service.date, 'days') : 0;

          if (
            service.unitControl === 'KM' &&
            (service.vehicle?.odometer_history?.current >=
              service.odometer + Number(service.frequency) - limitOdometer ||
              diffLastDate >= (Number(service.period) || Infinity) - limitDays)
          ) {
            console.log(service);
            urgentServices.push(service);
            return;
          }

          if (
            service.unitControl === 'H' &&
            (service.vehicle?.horimeter_history?.current >=
              service.horimeter + Number(service.frequency) - limitHorimeter ||
              diffLastDate >= (Number(service.period) || Infinity) - limitDays)
          ) {
            urgentServices.push(service);
            return;
          }
          pendentServices.push(service);
          return;
        });

        return;
      }

      pendingServices.forEach((pendingService) => {
        if (service.id !== pendingService.id) {
          return;
        }

        const diffLastDate =
          service.date !== 0 ? moment(new Date()).diff(service.date, 'days') : null;

        if (
          service.unitControl === 'KM' &&
          (service.vehicle?.odometer_history?.current >=
            service.odometer + Number(pendingService.frequency) - limitOdometer ||
            diffLastDate >= (Number(pendingService.period) || Infinity) - limitDays)
        ) {
          urgentServices.push(service);
          return;
        }

        if (
          service.unitControl === 'H' &&
          (service.vehicle?.horimeter_history?.current >=
            service.horimeter + Number(pendingService.frequency) - limitHorimeter ||
            diffLastDate >= (Number(pendingService.period) || Infinity) - limitDays)
        ) {
          urgentServices.push(service);
          return;
        }
        pendentServices.push(service);
      });
    });

    // FIM - UNIFICANDO SERVIÇOS COLOCANDO CADA UM EM TRÊS LISTAS SEPARADAS

    // SERVIÇOS FINALIZADOS

    const finalizedServices = [];

    const servicesAndCorrectives = [...servicesPreventive, ...correctiveOrders];

    servicesAndCorrectives.forEach((service) => {
      if (service.state === 'Fechada') {
        finalizedServices.push(service);
      }
    });

    setPendentServices(pendentServices);
    setUrgentServices(urgentServices);
    setFinalizedServices(finalizedServices);
    setOngoingServices(ongoingServices);
  }, [
    pendingServices,
    correctiveOrders,
    preventiveOrders,
    vehicles,
    limitOdometer,
    limitHorimeter,
    limitDays,
  ]);

  console.log(pendentServices, urgentServices);

  return {
    pendentServices,
    urgentServices,
    ongoingServices,
    finalizedServices,
    preventiveOrders,
    correctiveOrders,
  };
};
