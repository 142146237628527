import React, { useCallback } from 'react';
import { Input } from 'antd';
import { useField, useFormikContext } from 'formik';
import useCurrencyInput from './useCurrencyInput';

export default function CurrencyInput(props) {
  const [fieldProps] = useField(props.name);
  const formikBag = useFormikContext();

  const onChange = useCallback(
    value => {
      formikBag.setFieldValue(props.name, value);
      formikBag.setFieldTouched(props.name);
    },
    [formikBag, props.name],
  );

  const { formatedValue, handleBlur, handleChange } = useCurrencyInput({
    value: fieldProps.value,
    onChange: onChange,
    onBlur: onChange,
  });

  return (
    <Input
      {...props}
      type="phone"
      value={formatedValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}
