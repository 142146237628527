import React from 'react';
import { AuthContext } from '../../contexts/AuthProvider';
import Dashboard from './Dashboard';

export interface IAppProps {}

export default function DashboardWrapper(props: IAppProps) {
  const { firebaseOrganizationId } = React.useContext(AuthContext);

  if (!firebaseOrganizationId) return <span>Carregando...</span>;

  return (
    <div>
      <Dashboard organization={firebaseOrganizationId} />
    </div>
  );
}
