import { db } from '../../firebaseConfig';
import { useState, useEffect } from 'react';
import { useListAllVehiclesQuery } from '../../generated/graphql';

export const useSummaryData = (organization) => {
  const [pendingServices, setPendingServices] = useState([]);
  const [preventiveOrders, setPreventiveOrders] = useState([]);
  const [correctiveOrders, setCorrectiveOrders] = useState([]);
  const [{ data }] = useListAllVehiclesQuery({
    pollInterval: 10000,
    requestPolicy: 'cache-and-network',
  });

  const vehicles = data?.vehicle || [];

  useEffect(() => {
    return db
      .collection('organizations')
      .doc(organization)
      .collection('maintenancePlan')
      .onSnapshot((querySnapshot) => {
        const pendingServices = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setPendingServices(pendingServices);
      });
  }, [organization, setPendingServices]);

  useEffect(() => {
    return db
      .collection('organizations')
      .doc(organization)
      .collection('serviceOrdersPreventive')
      .onSnapshot((querySnapshot) => {
        const preventiveOrders = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          initDate: doc.data().initDate ? doc.data().initDate.toDate() : null,
          finalDate: doc.data().finalDate ? doc.data().finalDate.toDate() : null,
        }));
        const orderedPreventiveOrders = preventiveOrders.sort(function (a, b) {
          let dateA = a.initDate,
            dateB = b.initDate;
          return dateB - dateA;
        });
        setPreventiveOrders(orderedPreventiveOrders);
      });
  }, [organization, setPreventiveOrders]);

  useEffect(() => {
    return db
      .collection('organizations')
      .doc(organization)
      .collection('serviceOrders')
      .onSnapshot((querySnapshot) => {
        const correctiveOrders = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          initDate: doc.data().initDate ? doc.data().initDate.toDate() : null,
          finalDate: doc.data().finalDate ? doc.data().finalDate.toDate() : null,
        }));
        setCorrectiveOrders(correctiveOrders);
      });
  }, [organization, setCorrectiveOrders]);

  return { vehicles, pendingServices, preventiveOrders, correctiveOrders };
};
