import styled from 'styled-components';

export const ImportButton = styled.button`
  background-color: #fff;
  border: 1px solid rgb(50, 182, 80);
  border-radius: 4px;
  color: rgb(50, 182, 80);
  padding: 8px;
  font-size: 12px;
  margin-left: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;
