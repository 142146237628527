import { flatten } from 'lodash';
import { Order } from '../../../types';

export const getExcelOrderParts = (orders: Order[]) => {
  const orderWithoutParts = orders
    .filter((order) => !order.parts.length)
    .map((order) => ({ ...order, part: null }));

  const orderWithParts = flatten(
    orders
      .filter((order) => !!order.parts.length)
      .map((order) => order.parts.map((part) => ({ part: { ...part }, ...order }))),
  );

  const totalOrders = [...orderWithParts, ...orderWithoutParts];

  const formatOrders = totalOrders.map((order: any) => ({
    number: order.number,
    initDate: order.initDate,
    vehicle: order.vehicle.plate,
    provider: order.provider.trade,
    cost: order.laborCost + order.cost + order.partsCost,
    state: order.state,
    part: order.part?.name || '',
    partCategory: order.part?.category || '',
    partUnitControl: order.part?.unitControl || '',
    partMeanUnitPrice: order.part?.meanUnitPrice || '',
    partAmount: order.part?.amount || '',
    partCost: (order.part?.amount || 0) * (order.part?.meanUnitPrice || 0),
  }));

  return formatOrders;
};
