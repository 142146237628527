export const CATEGORIES = [
  'CABINE',
  'CARROCERIA',
  'CÂMBIO & TRANSMISSÃO',
  'DIREÇÃO',
  'EIXOS & LONGARINAS',
  'ELÉTRICA & ELETRÔNICA',
  'EMBREAGEM',
  'FREIOS',
  'MOTOR',
  'ÓLEOS & FLUIDOS',
  'OUTRA',
  'QUADROS & CARENAGENS',
  'SUSPENSÃO',
];

export const OTHER = 'OUTRA';

export const KM_UNIT_CONTROL = 'KM';

export const H_UNIT_CONTROL = 'H';
