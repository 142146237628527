import React from 'react';
import { AuthContext } from '../../../contexts/AuthProvider';
import ServiceOrderForm from './ServiceOrderForm';
import { useListAllVehiclesQuery } from '../../../generated/graphql';

export interface IAppProps {}

export default function ServiceOrderFormWrapper(props: IAppProps) {
  const { firebaseOrganizationId } = React.useContext(AuthContext);

  const [result] = useListAllVehiclesQuery({
    requestPolicy: 'network-only',
  });

  const { data } = result;

  const vehicles = data?.vehicle || [];

  if (!firebaseOrganizationId) return <span>Carregando...</span>;

  return (
    <div>
      <ServiceOrderForm organization={firebaseOrganizationId} vehicles={vehicles} />
    </div>
  );
}
