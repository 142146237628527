import React from 'react';
import { AuthContext } from '../../../contexts/AuthProvider';
import PartsContainer from './PartsContainer';

export interface IAppProps {}

export default function PartsContainerWrapper(props: IAppProps) {
  const { firebaseOrganizationId } = React.useContext(AuthContext);

  if (!firebaseOrganizationId) return <span>Carregando...</span>;

  return (
    <div>
      <PartsContainer organization={firebaseOrganizationId} />
    </div>
  );
}
