import { devtoolsExchange } from '@urql/devtools';
import { retryExchange } from '@urql/exchange-retry';
import WebSocket from 'isomorphic-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import {
  cacheExchange,
  createClient,
  dedupExchange,
  Exchange,
  fetchExchange,
  Operation,
  subscriptionExchange,
} from 'urql';
import { fromPromise, fromValue, map, mergeMap, pipe } from 'wonka';
import authClient from '../auth-client';

function fetchOptionsExchange(fn: any): Exchange {
  return ({ forward }) => (ops$) => {
    return pipe(
      ops$,
      mergeMap((operation: Operation) => {
        const result = fn(operation.context.fetchOptions);
        return pipe(
          (typeof result.then === 'function' ? fromPromise(result) : fromValue(result)) as any,
          map((fetchOptions: RequestInit | (() => RequestInit)) => ({
            ...operation,
            context: { ...operation.context, fetchOptions },
          })),
        );
      }),
      forward,
    );
  };
}

const getAuthParameters = async () => {
  // get the authentication token from local storage if it exists
  const headers = await authClient.getHttpHeaders();

  return {
    headers: headers,
  };
};

const subscriptionClient = new SubscriptionClient(
  `${process.env.REACT_APP_GRAPHQL_ENDPOINT}`.replace(/^(http)/, 'ws'),
  {
    lazy: true,
    reconnect: true,
    connectionParams: getAuthParameters,
    connectionCallback: (err) => {
      if (err) {
        subscriptionClient.close(false, false);
      }
    },
  },
  WebSocket,
);

const client = createClient({
  url: process.env.REACT_APP_GRAPHQL_ENDPOINT as string,
  exchanges: [
    devtoolsExchange,
    dedupExchange,
    cacheExchange,

    // Must be called before fetchExchange and after all others sync exchanges, respecting the rule Synchronous first, Asynchronous last
    fetchOptionsExchange(async (fetchOptions: any) => {
      const headers = await getAuthParameters();
      return {
        ...fetchOptions,
        ...headers,
      };
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: (operation) => subscriptionClient.request(operation),
    }),
    retryExchange({
      initialDelayMs: 1000,
      maxDelayMs: 15000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: (err) => !!(err && err.networkError),
    }) as any,
  ],
});

export default client;
