import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Popconfirm, Row, Table } from 'antd';
import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';
import { FaPen, FaTrash } from 'react-icons/fa';
import { ContainerTitle, Title } from '../../components/PageTitle/styles';
import { db } from '../../firebaseConfig';
import EditProvider from './EditProvider';
import NewProvider from './NewProvider';

export default class Providers extends Component {
  state = {
    providers: [],
    user: this.props.user,
    showDelete: false,
    showNew: false,
    showEdit: false,
    loading: true,
    editableProvider: undefined,
    deletableProvider: undefined,
    searchText: '',
    searchedColumn: '',
  };

  componentDidMount = async () => {
    const { organization } = this.props;

    await db
      .collection('organizations')
      .doc(organization)
      .collection('providers')
      .onSnapshot((querySnapshot) => {
        const providers = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        this.setState({ providers, loading: false });
      });
  };

  handleCloseNew = () => {
    this.setState({ showNew: false });
  };

  handleShowNew = () => {
    this.setState({ showNew: true });
  };

  handleCloseEdit = () => {
    this.setState({ showEdit: false });
  };

  handleCloseDelete = () => {
    this.setState({ showDelete: false });
  };

  handleDelete = async (record) => {
    const { organization } = this.props;
    const id = record.id;
    await db
      .collection('organizations')
      .doc(organization)
      .collection('providers')
      .doc(id)
      .delete()
      .catch((err) => console.log(err));
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Procurar...`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Procurar
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Limpar
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const { providers, showNew, showEdit, loading, editableProvider } = this.state;

    //Deixa em ordem alfabética
    const orderedProviders = providers
      .sort((a, b) => {
        const textA = a.company.toUpperCase();
        const textB = b.company.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map((provider, index) => ({
        ...provider,
        key: index + 1,
      }));

    const columnsTable = [
      {
        title: '#',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: 'Razão Social',
        dataIndex: 'company',
        key: 'company',
        ...this.getColumnSearchProps('company'),
      },
      {
        title: 'Nome Fantasia',
        dataIndex: 'trade',
        key: 'trade',
        ...this.getColumnSearchProps('trade'),
      },
      {
        title: 'CNPJ',
        dataIndex: 'CNPJ',
        key: 'CNPJ',
        ...this.getColumnSearchProps('CNPJ'),
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'CNPJ',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Telefone',
        dataIndex: 'phone',
        key: 'phone',
        ...this.getColumnSearchProps('phone'),
      },
      {
        title: 'Endereço',
        dataIndex: 'address',
        key: 'address',
        ...this.getColumnSearchProps('address'),
      },
      {
        title: 'Ações',
        key: 'action',
        render: (text, record) => (
          <>
            <FaPen
              style={{ cursor: 'pointer', marginRight: 12 }}
              onClick={() => this.setState({ editableProvider: record, showEdit: true })}
            />

            <Popconfirm
              title="Você gostaria de deletar esse fornecedor?"
              onConfirm={() => this.handleDelete(record)}
            >
              <FaTrash style={{ cursor: 'pointer' }} />
            </Popconfirm>
          </>
        ),
      },
    ];

    return (
      <div>
        <ContainerTitle>
          <Title>Fornecedores</Title>
          <div>
            <button onClick={this.handleShowNew} className="newProvider">
              Novo fornecedor
            </button>
          </div>
        </ContainerTitle>
        <Row>
          <Col span={24}>
            <Table
              pagination={false}
              dataSource={orderedProviders}
              columns={columnsTable}
              loading={loading}
            />
          </Col>
        </Row>
        <NewProvider
          organization={this.props.organization}
          visible={showNew}
          handleClose={this.handleCloseNew}
        />
        {showEdit && (
          <EditProvider
            organization={this.props.organization}
            visible={showEdit}
            handleClose={this.handleCloseEdit}
            provider={editableProvider}
          />
        )}
      </div>
    );
  }
}
