import React, { Component } from 'react';
import { Input, Row, Col, Modal } from 'antd';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SubmitButton } from './styles';

import { db } from '../../firebaseConfig';

export default class NewProvider extends Component {
  state = {
    CNPJ: '',
    address: '',
    company: '',
    trade: '',
  };

  addToFirebase = async (values) => {
    const { organization } = this.props;
    db.collection('organizations').doc(organization).collection('providers').add(values);
  };

  render() {
    const ProviderSchema = Yup.object().shape({
      CNPJ: Yup.number().typeError('Apenas números são permitidos').required('Campo obrigatório'),
      address: Yup.string().required('Campo obrigatório'),
      company: Yup.string().required('Campo obrigatório'),
      trade: Yup.string().required('Campo obrigatório'),
      email: Yup.string().email('Insira um e-mail válido'),
      phone: Yup.string().typeError('Insira um telefone válido'),
    });

    const { handleClose } = this.props;

    return (
      <div>
        <Formik
          onSubmit={(values, { resetForm, setSubmitting }) => {
            const toAddValues = {
              ...values,
              CNPJ: parseInt(values.CNPJ, 10),
              trade: values.trade.toUpperCase(),
              company: values.company.toUpperCase(),
            };
            this.addToFirebase(toAddValues)
              .then(() => {
                setSubmitting(false);
                handleClose();
                resetForm({});
              })
              .catch((err) => {
                console.log(err);
                setSubmitting(false);
                resetForm({});
              });
          }}
          validationSchema={ProviderSchema}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={this.state}
          render={({ handleSubmit }) => (
            <Form>
              <Modal
                visible={this.props.visible}
                onCancel={handleClose}
                title="Novo fornecedor"
                footer={
                  <div>
                    <SubmitButton onClick={handleSubmit}>Adicionar</SubmitButton>
                  </div>
                }
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <label className="label-form" htmlFor="company">
                      Razão social:
                      <ErrorMessage name="company">
                        {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                      </ErrorMessage>
                    </label>

                    <Field
                      name="company"
                      render={({ field /* _form */ }) => (
                        <Input {...field} placeholder="Ex.: Continental Pneus LTDA" />
                      )}
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={16}>
                  <Col span={12}>
                    <label className="label-form" htmlFor="trade">
                      Nome fantasia:
                      <ErrorMessage name="trade">
                        {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                      </ErrorMessage>
                    </label>
                    <Field
                      name="trade"
                      render={({ field /* _form */ }) => (
                        <Input {...field} placeholder="Ex.: Continental" />
                      )}
                    />
                  </Col>
                  <Col span={12}>
                    <label className="label-form" htmlFor="CNPJ">
                      CNPJ:
                      <ErrorMessage name="CNPJ">
                        {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                      </ErrorMessage>
                    </label>
                    <Field
                      name="CNPJ"
                      render={({ field /* _form */ }) => (
                        <Input {...field} placeholder="Ex.:11111111111111" />
                      )}
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={16}>
                  <Col span={12}>
                    <label className="label-form" htmlFor="phone">
                      Telefone:
                      <ErrorMessage name="phone">
                        {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                      </ErrorMessage>
                    </label>
                    <Field
                      name="phone"
                      render={({ field /* _form */ }) => (
                        <Input {...field} placeholder="Ex.: (99) 99999 9999" />
                      )}
                    />
                  </Col>
                  <Col span={12}>
                    <label className="label-form" htmlFor="email">
                      E-mail:
                      <ErrorMessage name="email">
                        {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                      </ErrorMessage>
                    </label>
                    <Field
                      name="email"
                      render={({ field /* _form */ }) => (
                        <Input {...field} placeholder="example@example.com" />
                      )}
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={16}>
                  <Col span={24}>
                    <label className="label-form" htmlFor="address">
                      Endereço:
                      <ErrorMessage name="address">
                        {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                      </ErrorMessage>
                    </label>
                    <Field
                      name="address"
                      render={({ field /* _form */ }) => (
                        <Input {...field} placeholder="Ex.: Rua João Gomes 751, 4584120, Bloco 2" />
                      )}
                    />
                  </Col>
                </Row>
              </Modal>
            </Form>
          )}
        />
      </div>
    );
  }
}
