import { useFormikContext } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import React, { ReactElement } from 'react';
import {
    Horimeter_History,
    Odometer_History
} from '../../../../../generated/graphql';

export default function InitialDatePicker(): ReactElement {
  const { values, setFieldValue }: any = useFormikContext();

  const setMeasures = (value: Date) => {
    const odometer_histories = values.vehicle?.odometer_histories ?? [];
    const horimeter_histories = values.vehicle?.horimeter_histories ?? [];

    const odometer =
      odometer_histories
        .filter(
          (register: Odometer_History) =>
            new Date(value).getTime() >= new Date(register.measured_at).getTime(),
        )
        .sort(
          (a: Odometer_History, b: Odometer_History) =>
            new Date(b.measured_at).getTime() - new Date(a.measured_at).getTime(),
        )[0]?.current ?? 0;

    const horimeter =
      horimeter_histories
        .filter(
          (register: Horimeter_History) =>
            new Date(value).getTime() >= new Date(register.measured_at).getTime(),
        )
        .sort(
          (a: Horimeter_History, b: Horimeter_History) =>
            new Date(b.measured_at).getTime() - new Date(a.measured_at).getTime(),
        )[0]?.current ?? 0;

    setFieldValue('odometer', odometer);
    setFieldValue('horimeter', horimeter);
  };

  return (
    <div>
      <label className="label-form" htmlFor="initDate">
        Inicia em:
      </label>
      <Form.Item name="initDate" style={{ marginBottom: 0 }}>
        <DatePicker
          name="initDate"
          style={{ width: '100%' }}
          allowClear={false}
          onChange={(date) => {
            // @ts-ignore
            setMeasures(date._d);
          }}
          format="DD/MM/YYYY"
        />
      </Form.Item>
    </div>
  );
}
