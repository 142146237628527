import { Modal, notification, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { db } from '../../../../../firebaseConfig';
import { ImportButton } from '../components/ImportButton';

const ImportService = ({ vehicle, organization, setServices }) => {
  const [modal, setModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(undefined);

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    db.collection('organizations')
      .doc(organization)
      .collection('maintenanceTemplate')
      .onSnapshot((querySnapshot) => {
        const plans = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setPlans(plans);
      });
  }, [organization]);

  const handleImport = async (services) => {
    const maintenancePlanRef = db
      .collection('organizations')
      .doc(organization)
      .collection('maintenancePlan');

    const servicesWithVehicles = services.map((s) => ({
      ...s,
      vehicle: vehicle,
    }));

    try {
      for (let service of servicesWithVehicles) {
        console.log(service);
        await maintenancePlanRef.add(service);
      }
    } catch (e) {
      console.error(e);
      notification['error']({
        message: 'Erro ao importar serviços',
        description: 'Contate o suporte',
      });
    } finally {
      setServices((oldList) => [...servicesWithVehicles, ...oldList]);
    }
  };

  const toggleModal = () => setModal(!modal);

  const handleChange = (value) => {
    const plan = plans.find(({ id }) => id === value);
    setSelectedPlan(plan);
  };

  const handleCancel = () => {
    setModal(false);
    setSelectedPlan(undefined);
  };

  const handleOk = () => {
    console.log(selectedPlan);
    handleImport(selectedPlan.services);
    setModal(false);
    setSelectedPlan(undefined);
  };

  return (
    <>
      <ImportButton onClick={toggleModal}>Importar Plano de Manutenção</ImportButton>
      <Modal
        title="Importar serviços de Plano de Manutenção"
        visible={modal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <button onClick={handleOk} key="submit" className="newVehicle" type="submit">
            Importar
          </button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          <Select style={{ width: 200 }} placeholder="Selecione um plano" onChange={handleChange}>
            {plans.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    </>
  );
};

export default ImportService;
