import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PartsWrapper from '../../modules/Parts';
import ProvidersWrapper from '../../modules/Providers';
import Vehicles from '../../modules/Vehicles';
import DetailVehicle from '../../modules/Vehicles/DetailVehicle';
import Content from './Content';
import DashboardWrapper from '../../modules/Dashboard';
import SummaryWrapper from '../../modules/Summary';
import ServiceOrdersWrapper from '../../modules/ServiceOrders';
import PartsContainerWrapper from '../../modules/Parts/Form/PartsContainerWrapper';
import ServiceOrderFormWrapper from '../../modules/ServiceOrders/ServiceOrderForm/ServiceOrderFormWrapper';
import PlansWrapper from '../../modules/MaintenancePlan';
import PlanFormWrapper from '../../modules/MaintenancePlan/PlanFormWrapper';

export default function Routes() {
  return (
    <Router>
      <Content>
        <Switch>
          <Route exact path="/">
            <SummaryWrapper />
          </Route>
          <Route exact path="/service-orders">
            <ServiceOrdersWrapper />
          </Route>
          <Route exact path="/service-orders/new">
            <ServiceOrderFormWrapper />
          </Route>
          <Route path="/service-orders/:id">
            <ServiceOrderFormWrapper />
          </Route>
          <Route path="/dashboard">
            <DashboardWrapper />
          </Route>
          <Route exact path="/vehicles-and-equipments">
            <Vehicles />
          </Route>
          <Route path="/vehicles-and-equipments/:id">
            <DetailVehicle />
          </Route>
          <Route exact path="/parts">
            <PartsWrapper />
          </Route>
          <Route exact path="/parts/new">
            <PartsContainerWrapper />
          </Route>
          <Route path="/parts/:id">
            <PartsContainerWrapper />
          </Route>
          <Route exact path="/plans">
            <PlansWrapper />
          </Route>
          <Route exact path="/plans/new">
            <PlanFormWrapper />
          </Route>
          <Route path="/plans/:id">
            <PlanFormWrapper />
          </Route>
          <Route path="/providers">
            <ProvidersWrapper />
          </Route>
        </Switch>
      </Content>
    </Router>
  );
}
