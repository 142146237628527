import { useFormikContext } from 'formik';
import { Form, InputNumber } from 'formik-antd';
import React, { ReactElement } from 'react';
import { Odometer_History } from '../../../../../generated/graphql';

export default function Odometer(): ReactElement {
  const { values, setFieldValue }: any = useFormikContext();

  const setMeasure = (value: number | string | undefined) => {
    setFieldValue('odometer', value);
  };

  const validateOdometer = (value: number) => {
    let error;
    const odometer_histories = values.vehicle?.odometer_histories ?? [];
    const date = values.initDate;

    const min_odometer =
      Number(
        odometer_histories
          .filter(
            (register: Odometer_History) =>
              new Date(date).getTime() >= new Date(register.measured_at).getTime(),
          )
          .sort(
            (a: Odometer_History, b: Odometer_History) =>
              new Date(b.measured_at).getTime() - new Date(a.measured_at).getTime(),
          )[0]
          ?.current.toFixed(2),
      ) ?? 0;

    const max_odometer =
      Number(
        odometer_histories
          .filter(
            (register: Odometer_History) =>
              new Date(date).getTime() <= new Date(register.measured_at).getTime(),
          )
          .sort(
            (a: Odometer_History, b: Odometer_History) =>
              new Date(b.measured_at).getTime() - new Date(a.measured_at).getTime(),
          )[0]
          ?.current.toFixed(2),
      ) ?? Infinity;

    if (value < min_odometer) {
      error = 'Insira um valor maior';
      return error;
    }

    if (value > max_odometer) {
      error = 'Insira um valor menor';
    }

    return error;
  };

  return (
    <div>
      <label className="label-form" htmlFor="odometer">
        Odômetro:
      </label>
      <Form.Item name="odometer" style={{ marginBottom: 0 }}>
        <InputNumber
          validate={validateOdometer}
          onChange={(value: string | number | undefined) => {
            setMeasure(value);
          }}
          name="odometer"
          placeholder="Ex.: 550000"
          style={{ width: '100%' }}
        ></InputNumber>
      </Form.Item>
    </div>
  );
}
