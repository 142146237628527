import { Col, Row } from 'antd';
import { groupBy } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FaDollarSign } from 'react-icons/fa';
import logo from './logo.png';
import styles from './PrintVehicleCosts.module.css';
import {
  Content,
  CustomRow,
  Header,
  Label,
  Logo,
  OrderContainer,
  OrderInfoContainer,
  OrderTitle,
  SubTitle,
  Tag,
  Title,
  TotalCost,
} from './styles';
import { Service, Order, Part } from '../../../../types';

export default function PrintVehicleCosts(props: any) {
  const { serviceOrders, serviceOrdersPreventive, date } = props;

  const orders = [...serviceOrdersPreventive, ...serviceOrders];

  const initDate = moment(date[0]).format('DD/MM/YY');
  const finalDate = moment(date[1]).format('DD/MM/YY');
  const totalCost = useMemo(() => {
    return orders
      .map(
        (order) =>
          order.cost +
          (order.laborCost || 0) +
          parseFloat(order.parts.reduce((a: number, b: Part) => a + b.meanUnitPrice * b.amount, 0)),
      )
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }, [orders]);

  const printedVehicles = useMemo(() => {
    if (typeof orders !== undefined) {
      const groupedByVehicle = groupBy(orders, (order: Order) => order?.vehicle?.plate);
      const vehicles = [];
      for (let key in groupedByVehicle) {
        vehicles.push(groupedByVehicle[key]);
      }

      return vehicles;
    }

    return [];
  }, [orders]);

  return (
    <div ref={props.forRef} className={styles.container}>
      <Header>
        <Logo src={logo} />
        <div>
          <Title>Relatório de Custos</Title>
          <p>
            De <strong>{initDate}</strong> a <strong>{finalDate}</strong>
          </p>
        </div>
      </Header>
      <Content>
        <Label>
          <FaDollarSign /> Custo total no período
        </Label>
        <TotalCost>R$ {totalCost.toFixed(2)}</TotalCost>
        <h5 style={{ fontWeight: 'bold' }}>DETALHAMENTO POR VEÍCULO</h5>
        <br />
        {printedVehicles.map((vehicles, index) => (
          <div key={index}>
            <br />
            <Tag>
              <span style={{ fontSize: '1.25rem' }}>Placa - </span>
              {vehicles[0].vehicle.plate}
            </Tag>
            <hr />
            <OrderContainer>
              <OrderTitle>
                ORDENS DE SERVIÇO
                <span style={{ color: 'gray', paddingLeft: '1rem' }}>{vehicles.length} itens</span>
              </OrderTitle>
              {vehicles.map((order) => (
                <div>
                  <OrderInfoContainer>
                    <CustomRow>
                      <h6>Número</h6>
                      <h6 style={{ textAlign: 'right', fontWeight: 'bold' }}>{order.number}</h6>
                    </CustomRow>
                    <CustomRow>
                      <h6>Início</h6>
                      <h6 style={{ textAlign: 'right', fontWeight: 'bold' }}>
                        {moment(order.initDate).format('DD/MM/YY')}
                      </h6>
                    </CustomRow>
                    <CustomRow>
                      <h6>Fim</h6>
                      <h6 style={{ textAlign: 'right', fontWeight: 'bold' }}>
                        {moment(order.finalDate).format('DD/MM/YY')}
                      </h6>
                    </CustomRow>
                  </OrderInfoContainer>

                  {!!order.services.length && (
                    <>
                      <br />
                      <Row justify="space-between">
                        <Col>
                          <SubTitle>Serviços</SubTitle>
                          <ul style={{ paddingLeft: '1rem' }}>
                            {order.services.map((s: Service, index: number) => (
                              <li key={index}>{s.service}</li>
                            ))}
                          </ul>
                        </Col>
                      </Row>
                    </>
                  )}

                  {!!order.parts.length && (
                    <>
                      <br />
                      <SubTitle>Peças</SubTitle>
                      {order.parts.map((p: Part, index: number) => (
                        <Row gutter={16} key={index}>
                          <Col span={8}>
                            <p>{p.name}</p>
                          </Col>
                          <Col span={4}>
                            <p>{p.amount} und.</p>
                          </Col>
                          <Col span={8}>
                            <p>R$ {p.meanUnitPrice} / und.</p>
                          </Col>
                          <Col span={4}>
                            <p style={{ textAlign: 'right' }}>
                              <strong>R$ {(p.meanUnitPrice * p.amount).toFixed(2)}</strong>
                            </p>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}

                  <br />
                  <CustomRow>
                    <h6>Custo - Mão de Obra</h6>
                    <h6 style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {order.laborCost.toFixed(2)}
                    </h6>
                  </CustomRow>
                  <CustomRow>
                    <h6>Custo - Extras</h6>
                    <h6 style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {order?.cost.toFixed(2) || 0}
                    </h6>
                  </CustomRow>
                  <br />
                  <CustomRow>
                    <h6>CUSTO TOTAL</h6>
                    <h6 style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {(order.laborCost + order.cost + order.partsCost).toFixed(2)}
                    </h6>
                  </CustomRow>
                  <br />
                  <hr />
                </div>
              ))}
              <footer></footer>
            </OrderContainer>
          </div>
        ))}
      </Content>
    </div>
  );
}
