import { CaretRightFilled } from '@ant-design/icons';
import { Col, Collapse, Popconfirm, Row } from 'antd';
import pt_BR from 'antd/lib/locale-provider/pt_BR';
import { FieldArray, Formik } from 'formik';
import { Checkbox, DatePicker, Form, Input, InputNumber, Select } from 'formik-antd';
import { flatten } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaExclamationTriangle, FaTrash } from 'react-icons/fa';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput';
import { Title } from '../../../components/DetailPageTitle/styles';
import { Headline } from '../../../components/Headline';
import { Subtitle } from '../../../components/PageSubtitle/styles';
import { db } from '../../../firebaseConfig';
import { servicesStyle } from '../helpers/services-transfer-style';
import ServicesTable from '../ServicesTable';
import InitialDatePicker from './components/InitialDatePicker/InitialDatePicker';
import SelectVehicle from './components/SelectVehicle/SelectVehicle';
import Odometer from './components/Odometer/Odometer';

const { TextArea } = Input;
const { Panel } = Collapse;
const Option = Select.Option;

const formatParts = (correctives, preventives, parts, purchases) => {
  const partsCorrective = flatten(correctives.map((o) => o.parts || []));
  const partsPreventive = flatten(preventives.map((o) => o.parts || []));

  const usedParts = [...partsCorrective, ...partsPreventive];

  const formatParts = parts.map((part) => {
    const filteredPurchases = purchases.filter((purchase) => purchase.parts[0].id === part.id);
    const amountPurchased = filteredPurchases.reduce((a, b) => a + b.amount, 0);
    const valuePurchased = filteredPurchases.reduce((a, b) => a + b.amount * b.unitPrice, 0);
    const meanUnitPrice = Math.round((valuePurchased / amountPurchased) * 100) / 100 || 0;

    const filteredUsedParts = usedParts.filter((p) => {
      if (p.length === 0) return false;

      return part.id === p.id;
    });

    const amountSpent = filteredUsedParts.reduce((a, b) => a + b.amount, 0);
    const valueSpent = filteredUsedParts.reduce((a, b) => a + b.amount * b.meanUnitPrice, 0);

    return {
      ...part,
      totalAmount: amountPurchased - amountSpent,
      totalValue: valuePurchased - valueSpent,
      meanUnitPrice,
    };
  });

  return formatParts;
};

const usePartsWithPurchases = (organization) => {
  const [parts, setParts] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [serviceOrders, setServiceOrders] = useState([]);
  const [preventiveServiceOrders, setpreventiveServiceOrders] = useState([]);

  useEffect(() => {
    db.collection('organizations')
      .doc(organization)
      .collection('serviceOrders')
      .onSnapshot((querySnapshot) => {
        const serviceOrders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          initDate: doc.data().initDate ? doc.data().initDate.toDate() : null,
          finalDate: doc.data().finalDate ? doc.data().finalDate.toDate() : null,
        }));
        const formatServiceOrders = serviceOrders.sort(function (a, b) {
          let dateA = a.initDate,
            dateB = b.initDate;
          return dateB - dateA;
        });
        setServiceOrders(formatServiceOrders);
      });
  }, [organization]);

  useEffect(() => {
    db.collection('organizations')
      .doc(organization)
      .collection('serviceOrdersPreventive')
      .onSnapshot((querySnapshot) => {
        const serviceOrdersPreventive = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          initDate: doc.data().initDate ? doc.data().initDate.toDate() : null,
          finalDate: doc.data().finalDate ? doc.data().finalDate.toDate() : null,
        }));
        const formatServiceOrdersPreventive = serviceOrdersPreventive.sort(function (a, b) {
          let dateA = a.initDate,
            dateB = b.initDate;
          return dateB - dateA;
        });
        setpreventiveServiceOrders(formatServiceOrdersPreventive);
      });
  }, [organization]);

  useEffect(() => {
    db.collection('organizations')
      .doc(organization)
      .collection('serviceOrders')
      .onSnapshot((querySnapshot) => {
        const serviceOrders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          initDate: doc.data().initDate ? doc.data().initDate.toDate() : null,
          finalDate: doc.data().finalDate ? doc.data().finalDate.toDate() : null,
        }));
        const formatServiceOrders = serviceOrders.sort(function (a, b) {
          let dateA = a.initDate,
            dateB = b.initDate;
          return dateB - dateA;
        });
        setServiceOrders(formatServiceOrders);
      });
  }, [organization]);

  useEffect(() => {
    db.collection('organizations')
      .doc(organization)
      .collection('parts')
      .onSnapshot((querySnapshot) => {
        const parts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setParts(parts);
      });
  }, [organization]);

  useEffect(() => {
    db.collection('organizations')
      .doc(organization)
      .collection('purchases')
      .onSnapshot((querySnapshot) => {
        const purchases = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          date: doc.data().date.toDate(),
        }));
        setPurchases(purchases);
      });
  }, [organization]);

  const formattedParts = formatParts(serviceOrders, preventiveServiceOrders, parts, purchases);

  return {
    parts: formattedParts,
  };
};

const useProviders = (organization) => {
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    db.collection('organizations')
      .doc(organization)
      .collection('providers')
      .onSnapshot((querySnapshot) => {
        const providers = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProviders(providers);
      });
  }, [organization]);

  return {
    providers,
  };
};

const useServiceOrder = (id, organization) => {
  const [serviceOrder, setServiceOrder] = useState(undefined);

  useEffect(() => {
    if (!id || !organization) {
      return;
    }

    db.collection('organizations')
      .doc(organization)
      .collection('serviceOrders')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setServiceOrder({
            ...doc.data(),
            id: doc.id,
            vehicle: doc.data().vehicle,
            provider: doc.data().provider.id,
            initDate: doc.data().initDate.toDate(),
            finalDate: doc.data().finalDate ? doc.data().finalDate.toDate() : null,
          });
        }
      });

    db.collection('organizations')
      .doc(organization)
      .collection('serviceOrdersPreventive')
      .doc(id)
      .get()
      .then((order) => {
        if (order.exists) {
          db.collection('organizations')
            .doc(organization)
            .collection('maintenancePlan')
            .where('vehicle.id', '==', order.data().vehicle.id)
            .get()
            .then((servicesSnap) => {
              const pendingServices = servicesSnap.docs.map((s) => ({
                ...s.data(),
                id: s.id,
              }));
              setServiceOrder({
                ...order.data(),
                id: order.id,
                pendingServices: pendingServices,
                targetServices: order.data().services.map((s) => s.key),
                vehicle: order.data().vehicle,
                provider: order.data().provider.id,
                initDate: order.data().initDate.toDate(),
                finalDate: order.data().finalDate ? order.data().finalDate.toDate() : null,
              });
            });
        }
      });
  }, [id, organization]);

  return { serviceOrder };
};

const ServiceOrderForm = (props) => {
  let vehicle = undefined;
  let services = undefined;
  let preventiveOrders = undefined;

  const history = useHistory();
  const location = useLocation();

  vehicle = location.state?.vehicle;
  services = location.state?.services;
  preventiveOrders = location.state?.preventiveOrders;

  const { id } = useParams();

  const { organization } = props;
  const { parts } = usePartsWithPurchases(organization);
  const { providers } = useProviders(organization);
  const { serviceOrder } = useServiceOrder(id, organization);

  const { vehicles } = props;

  const handleVehiclePlan = async (id, setFieldValue) => {
    await db
      .collection('organizations')
      .doc(organization)
      .collection('maintenancePlan')
      .where('vehicle.id', '==', id)
      .onSnapshot(async (querySnapshot) => {
        const pendingServices = await querySnapshot.docs.map((doc, index) => ({
          id: doc.id,
          key: index,
          ...doc.data(),
          vehicle: {
            ...doc.data().vehicle,
            CIPP: doc.data().vehicle.CIPP ? doc.data().vehicle.CIPP.toDate() : null,
            CIV: doc.data().vehicle.CIV ? doc.data().vehicle.CIV.toDate() : null,
            CRLV: doc.data().vehicle.CRLV ? doc.data().vehicle.CRLV.toDate() : null,
            tachograph: doc.data().vehicle.tachograph
              ? doc.data().vehicle.tachograph.toDate()
              : null,
            entryDate: doc.data().vehicle.entryDate ? doc.data().vehicle.entryDate.toDate() : null,
          },
        }));
        setFieldValue('pendingServices', pendingServices);
      });
  };

  const OrderSchema = Yup.object().shape({
    number: Yup.string().required('Campo obrigatório'),
    vehicle: Yup.string().required('Campo obrigatório'),
    provider: Yup.string().required('Campo obrigatório'),
    state: Yup.string().required('Campo obrigatório'),
    maintenanceType: Yup.string().required('Campo obrigatório'),
    cost: Yup.number().required('Campo obrigatório'),
    laborCost: Yup.number().required('Campo obrigatório'),
  });

  const initialOrder = id
    ? {
        checkHorimeter: false,
        checkOdometer: false,
        targetServices: [],
        ...serviceOrder,
      }
    : {
        number:
          preventiveOrders && preventiveOrders.length && parseInt(preventiveOrders[0].number) + 1,
        vehicle: vehicle || undefined,
        odometer: vehicle?.odometer_history?.current || 0,
        horimeter: vehicle?.horimeter_history?.current || 0,
        maintenanceType: services ? 'Preventiva' : 'Corretiva',
        checkOdometer: false,
        checkHorimeter: false,
        parts: [],
        state: 'Aberta',
        driver: null,
        initDate: new Date(),
        finalDate: null,
        description: null,
        cost: undefined,
        laborCost: undefined,
        provider: undefined,
        pendingServices: services || [],
        targetServices: [],
      };

  const createOrder = async (obj) => {
    const order = {
      ...obj,
      provider: providers.find(({ id }) => id === obj.provider),
    };

    if (obj.maintenanceType === 'Preventiva') {
      await db
        .collection('organizations')
        .doc(organization)
        .collection('serviceOrdersPreventive')
        .add(order)
        .then(() => history.push('/service-orders/'))
        .catch((e) => console.error(e));
    }

    if (obj.maintenanceType === 'Corretiva') {
      await db
        .collection('organizations')
        .doc(organization)
        .collection('serviceOrders')
        .add(order)
        .then(() => history.push('/service-orders/'))
        .catch((e) => console.error(e));
    }
  };

  const editOrder = async (obj) => {
    const order = {
      ...obj,
      provider: providers.find(({ id }) => id === obj.provider),
    };

    if (obj.maintenanceType === 'Preventiva') {
      await db
        .collection('organizations')
        .doc(organization)
        .collection('serviceOrdersPreventive')
        .doc(id)
        .update(order)
        .then(() => history.push('/service-orders/'))
        .catch((e) => console.error(e));
    }

    if (obj.maintenanceType === 'Corretiva') {
      await db
        .collection('organizations')
        .doc(organization)
        .collection('serviceOrders')
        .doc(id)
        .update(order)
        .then(() => history.push('/service-orders/'))
        .catch((e) => console.error(e));
    }
  };

  const hasPartError = (values) => {
    if (!values.parts) return;

    let hasError = false;

    values.parts.forEach((part) => {
      if (part.totalAmount < part.amount) {
        hasError = true;
      }
    });

    return hasError;
  };

  console.log(initialOrder);

  return (
    <div>
      <div className="arrow-left">
        <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
          <FaArrowLeft style={{ fontSize: '2em', color: '#AAA' }} />
        </span>
      </div>
      <Title>Formulário de Ordem de Serviço</Title>
      <div style={{ marginTop: '1rem' }}>
        <Formik
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            const {
              cost,
              description,
              driver,
              finalDate,
              horimeter = 0,
              initDate,
              laborCost,
              maintenanceType,
              number,
              odometer = 0,
              parts,
              provider,
              pendingServices,
              targetServices,
              state,
              vehicle,
            } = values;

            const partsCost = parts
              ? parts
                  .map(({ amount, meanUnitPrice }) => amount * meanUnitPrice)
                  .reduce((a, b) => a + b, 0)
              : null;

            const services = [];

            targetServices.forEach((key) => {
              pendingServices.forEach((service) => {
                if (key === service.key) {
                  services.push({
                    ...service,
                    odometer: odometer ?? 0,
                    horimeter: horimeter ?? 0,
                    finalDate: values.finalDate,
                  });
                }
              });
            });

            const order = {
              cost,
              description,
              driver,
              finalDate,
              horimeter,
              initDate: new Date(initDate),
              laborCost,
              maintenanceType,
              number,
              odometer,
              parts,
              partsCost,
              provider,
              services,
              state,
              vehicle,
            };

            id ? editOrder(order) : createOrder(order);
            setSubmitting(false);
          }}
          validationSchema={OrderSchema}
          initialValues={initialOrder}
          validateOnChange={true}
          validateOnBlur={false}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="shadow-container">
                <Subtitle>Informações</Subtitle>
                <Row gutter={16}>
                  <Col span={6}>
                    <InitialDatePicker />
                  </Col>
                  <Col span={6}>
                    <label className="label-form" htmlFor="finalDate">
                      Termina em:
                    </label>
                    <Form.Item name="finalDate" style={{ marginBottom: 0 }}>
                      <DatePicker
                        name="finalDate"
                        style={{ width: '100%' }}
                        disabledDate={(current) => {
                          return current && current <= moment(values.initDate).endOf('day');
                        }}
                        allowClear={false}
                        onChange={(date) => setFieldValue('finalDate', date._d)}
                        locale={pt_BR}
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <br />
                <Row gutter={16}>
                  <Col span={6}>
                    <SelectVehicle handleVehiclePlan={handleVehiclePlan} />
                  </Col>
                  <Col span={6}>
                    <Odometer />
                  </Col>
                  <Col span={6}>
                    <label className="label-form" htmlFor="horimeter">
                      Horímetro:
                    </label>
                    <Form.Item name="horimeter" style={{ marginBottom: 0 }}>
                      <InputNumber
                        name="horimeter"
                        placeholder="Ex.: 5000"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                    <Checkbox
                      name="checkHorimeter"
                      className="checkbox"
                      checked={values.checkHorimeter}
                    >
                      Atualizar horímetro do veículo
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <label className="label-form" htmlFor="horimeter">
                      Tipo de Ordem de Serviço:
                    </label>
                    <Form.Item name="maintenanceType">
                      <Select name="maintenanceType">
                        <Option value="Corretiva">Corretiva</Option>
                        <Option value="Preventiva">Preventiva</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              {values.maintenanceType === 'Preventiva' && (
                <div className="shadow-container">
                  <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <CaretRightFilled
                        style={{ marginTop: '0.45rem' }}
                        rotate={isActive ? 90 : 0}
                      />
                    )}
                  >
                    <Panel header={<h2>Serviços</h2>} key="1" style={servicesStyle}>
                      <p className="instruction__new-preventive">
                        Defina os serviços a serem feitos nesta manutenção
                      </p>
                      <ServicesTable
                        vehicles={vehicles}
                        pendingServices={values.pendingServices}
                        targetServices={values.targetServices}
                        handleTargetServices={(services) =>
                          setFieldValue('targetServices', services)
                        }
                      />
                    </Panel>
                  </Collapse>
                </div>
              )}

              <div className="shadow-container">
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <CaretRightFilled style={{ marginTop: '0.45rem' }} rotate={isActive ? 90 : 0} />
                  )}
                >
                  <Panel
                    header={<Subtitle>Peças e Insumos</Subtitle>}
                    key="1"
                    style={servicesStyle}
                  >
                    <p className="instruction__new-preventive">
                      Defina os itens do seu estoque a serem utilizados nos serviços desta ordem
                    </p>
                    <FieldArray name="parts">
                      {({ push, remove }) => (
                        <>
                          <div style={{ marginBottom: 30 }}>
                            <Headline className="subtitle-container">
                              Adicionar peças nesta ordem de serviço
                            </Headline>
                            <Select
                              showSearch
                              value=""
                              style={{ width: '50%', marginTop: '1rem' }}
                              placeholder="Ex.: Fluido de Freio"
                              optionFilterProp="children"
                              onChange={(value) => {
                                const selected = parts.find(({ id }) => id === value);
                                push({
                                  ...selected,
                                  amount: 0,
                                });
                              }}
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                              }
                            >
                              {parts.map(({ id, name }) => (
                                <Option key={id} value={id}>
                                  {name}
                                </Option>
                              ))}
                            </Select>
                            {hasPartError(values) && (
                              <p style={{ color: 'red', marginTop: '1rem' }}>
                                <FaExclamationTriangle
                                  style={{
                                    marginRight: '0.4rem',
                                    marginBottom: '0.2rem',
                                  }}
                                />
                                Atenção, você inseriu peças a mais do que possui no seu estoque.
                              </p>
                            )}
                          </div>

                          <div>
                            <div>
                              <Row
                                gutter={12}
                                style={{
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                }}
                              >
                                <Col span={1}>
                                  <span>#</span>
                                </Col>
                                <Col span={3}>
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      textAlign: 'left',
                                      color: 'rgba(0, 0, 0, 0.85)',
                                    }}
                                  >
                                    Nome
                                  </span>
                                </Col>
                                <Col span={3}>
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      textAlign: 'left',
                                      color: 'rgba(0, 0, 0, 0.85)',
                                    }}
                                  >
                                    Quantidade em Estoque
                                  </span>
                                </Col>
                                <Col span={3}>
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      textAlign: 'left',
                                      color: 'rgba(0, 0, 0, 0.85)',
                                    }}
                                  >
                                    Unidade de Controle
                                  </span>
                                </Col>
                                <Col span={3}>
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      textAlign: 'left',
                                      color: 'rgba(0, 0, 0, 0.85)',
                                    }}
                                  >
                                    Quantidade de Peças
                                  </span>
                                </Col>
                                <Col span={4}>
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      textAlign: 'left',
                                      color: 'rgba(0, 0, 0, 0.85)',
                                    }}
                                  >
                                    Preço unitário (R$)
                                  </span>
                                </Col>
                                <Col span={4}>
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      textAlign: 'left',
                                      color: 'rgba(0, 0, 0, 0.85)',
                                    }}
                                  >
                                    Preço Total (R$)
                                  </span>
                                </Col>
                                <Col span={3}>
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      textAlign: 'left',
                                      color: 'rgba(0, 0, 0, 0.85)',
                                    }}
                                  >
                                    Ação
                                  </span>
                                </Col>
                              </Row>
                            </div>
                            <div>
                              {values.parts &&
                                values.parts.map((item, key) => (
                                  <Row
                                    key={key}
                                    gutter={12}
                                    style={{
                                      paddingTop: 16,
                                      paddingBottom: 16,
                                      borderTop: 'solid 1px',
                                      borderBottom: 'solid 1px',
                                      borderColor: 'rgb(128, 128, 128, 0.25)',
                                    }}
                                  >
                                    <Col span={1} style={{ paddingTop: '0.35rem' }}>
                                      <span>{key + 1}</span>
                                    </Col>
                                    <Col span={3} style={{ paddingTop: '0.35rem' }}>
                                      <span>{item.name}</span>
                                    </Col>
                                    <Col span={3} style={{ paddingTop: '0.35rem' }}>
                                      <span>{item.totalAmount}</span>
                                    </Col>
                                    <Col span={3} style={{ paddingTop: '0.35rem' }}>
                                      <span>{item.unitControl}</span>
                                    </Col>
                                    <Col span={3}>
                                      <Input
                                        name={`parts.${key}.amount`}
                                        style={{ width: '70%' }}
                                        type="number"
                                        placeholder="Ex.: 50"
                                      />
                                      {}
                                    </Col>
                                    <Col span={4} style={{ paddingTop: '0.35rem' }}>
                                      <span>
                                        {/* {Math.round(item.meanUnitPrice * 100) /
                                          100} */}
                                        {item.meanUnitPrice}
                                      </span>
                                    </Col>
                                    <Col span={4} style={{ paddingTop: '0.35rem' }}>
                                      <span>{(item.amount * item.meanUnitPrice).toFixed(2)}</span>
                                    </Col>
                                    <Col span={3}>
                                      <Popconfirm
                                        title="Tem certeza?"
                                        onConfirm={() => remove(key)}
                                      >
                                        <FaTrash
                                          style={{
                                            cursor: 'pointer',
                                            marginRight: 12,
                                            marginTop: '0.65rem',
                                          }}
                                        />
                                      </Popconfirm>
                                    </Col>
                                  </Row>
                                ))}
                            </div>
                          </div>

                          {/* SUBTOTAL */}
                          <div
                            style={{
                              textAlign: 'right',
                              marginTop: '1.5rem',
                            }}
                          >
                            <p
                              style={{
                                fontSize: '1rem',
                                color: 'gray',
                                marginBottom: 0,
                              }}
                            >
                              Subtotal
                            </p>
                            <span
                              style={{
                                fontWeight: 'bold',
                                fontSize: `1.25rem`,
                              }}
                            >
                              R${' '}
                              {values.parts
                                ? values.parts
                                    .map(({ amount, meanUnitPrice }) => amount * meanUnitPrice)
                                    .reduce((a, b) => a + b, 0)
                                    .toFixed(2)
                                : '0,00'}
                            </span>
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </Panel>
                </Collapse>
              </div>
              <div className="shadow-container">
                <Subtitle>Dados</Subtitle>
                <p className="instruction__new-preventive">
                  Insira as informações desta ordem de serviço
                </p>
                <Row gutter={16}>
                  <Col span={6}>
                    <label className="label-form" htmlFor="number">
                      Numero:
                    </label>
                    <Form.Item name="number" style={{ marginBottom: 0 }}>
                      <InputNumber
                        name="number"
                        placeholder="Ex.: 1111"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <label className="label-form" htmlFor="state">
                      Estado:
                    </label>
                    <Form.Item name="state" style={{ marginBottom: 0 }}>
                      <Select name="state" defaultValue="Aberta">
                        <Option value="Fechada">Fechada</Option>
                        <Option value="Aberta">Aberta</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <label className="label-form" htmlFor="driver">
                      Motorista:
                    </label>
                    <Form.Item name="driver" style={{ marginBottom: 0 }}>
                      <Input name="driver" placeholder="Ex.: Manoel Carvalho" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <label className="label-form" htmlFor="provider">
                      Fornecedor:
                    </label>
                    <Form.Item name="provider" style={{ marginBottom: 0 }}>
                      <Select name="provider">
                        {providers.map((provider, index) => (
                          <Option key={index} value={provider.id}>
                            {provider.company}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <label className="label-form" htmlFor="cost">
                      Custos Extras (R$):
                    </label>
                    <Form.Item name="cost" style={{ marginBottom: 0 }}>
                      <CurrencyInput
                        type="number"
                        name="cost"
                        placeholder="Valor em R$"
                        style={{ textAlign: 'right' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <label className="label-form" htmlFor="laborCost">
                      Custo - Mão de Obra (R$):
                    </label>
                    <Form.Item name="laborCost" style={{ marginBottom: 0 }}>
                      <CurrencyInput
                        type="number"
                        name="laborCost"
                        placeholder="Valor em R$"
                        style={{ textAlign: 'right' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <label className="label-form" htmlFor="description">
                      Descrição do serviço:
                    </label>
                    <Form.Item name="description" style={{ marginBottom: 0 }}>
                      <TextArea
                        rows={4}
                        name="description"
                        placeholder="Insira uma descrição dos serviços realizados"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <button
                style={{ marginTop: '1rem' }}
                className="newOrder"
                type="submit"
                disabled={hasPartError(values)}
              >
                Adicionar
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ServiceOrderForm;
