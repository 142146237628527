import { QuestionCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const { Column } = Table;

const PlansTable = ({ plans, loading, handleDelete }) => {
  const [data, setData] = useState(plans);
  const [searchText, setsearchText] = useState('');
  const [filterDropdownVisible, setfilterDropdownVisible] = useState(false);

  useEffect(() => {
    setData(plans);
  }, [plans]);

  const onSearch = () => {
    const reg = new RegExp(searchText, 'gi');
    setfilterDropdownVisible(false);
    setsearchText('');
    setData(
      plans
        .map((record) => {
          const match = record.name.match(reg);
          if (!match) {
            return null;
          }
          return {
            ...record,
          };
        })
        .filter((record) => !!record),
    );
  };

  const handleReset = (clearFilters) => {
    setsearchText('');
    onSearch();
  };

  return (
    <div>
      <Table rowKey={({ id }) => id} pagination={false} dataSource={data} loading={loading}>
        <Column
          title="Plano"
          dataIndex="name"
          key="name"
          filterIcon={<SearchOutlined />}
          filterDropdownVisible={filterDropdownVisible}
          filterDropdown={() => (
            <div style={{ padding: 16 }}>
              <Input
                style={{ marginBottom: '1rem' }}
                placeholder="Pesquisar"
                value={searchText}
                onChange={(e) => setsearchText(e.target.value)}
                onPressEnter={onSearch}
              />
              <Button
                style={{ marginRight: '1rem' }}
                type="primary"
                onClick={onSearch}
                onPressEnter={onSearch}
              >
                Pesquisar
              </Button>
              <Button type="secondary" onClick={handleReset}>
                Limpar
              </Button>
            </div>
          )}
          onFilterDropdownVisibleChange={(visible) => setfilterDropdownVisible(visible)}
          onFilter={(value, record) => {
            return record['name'].toString().toLowerCase().includes(value.toLowerCase());
          }}
        />
        <Column
          align="right"
          key="services"
          title="Serviços"
          render={(_, record) => record.services.length}
          sorter={(a, b) => a.services.length - b.services.length}
          sortDirections={['descend', 'ascend']}
        />
        <Column
          title="Ações"
          align="right"
          key="actions"
          dataIndex="id"
          render={(id) => (
            <>
              <Link to={`/plans/${id}`}>
                <FaPen
                  style={{
                    cursor: 'pointer',
                    color: '#505659',
                    marginRight: 12,
                  }}
                />
              </Link>
              <Popconfirm
                title="Tem certeza？"
                icon={<QuestionCircleFilled style={{ color: 'red' }} />}
                onConfirm={() => handleDelete(id)}
              >
                <FaTrash style={{ cursor: 'pointer' }} />
              </Popconfirm>
            </>
          )}
        />
      </Table>
    </div>
  );
};

export default PlansTable;
