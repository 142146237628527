import { Card, Col } from 'antd';
import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
import Loading from '../../components/Loading';

export default class TotalCost extends Component {
  render() {
    const { serviceOrders, serviceOrdersPreventive, loading } = this.props;

    const orders = [...serviceOrders, ...serviceOrdersPreventive];

    const data = orders
      .map(
        (order) =>
          order.cost +
          (order.laborCost || 0) +
          parseFloat(order.parts.reduce((a, b) => a + b.meanUnitPrice * b.amount, 0)),
      )
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return (
      <Col span={8}>
        <Card size="small" title="Custo total" loading={false}>
          {loading ? (
            <Loading type={'spin'} color={'#001132'} width={32} />
          ) : (
            <p className="card-number">
              <CurrencyFormat
                value={data}
                displayType={'text'}
                thousandSeparator={'.'}
                prefix={'R$ '}
                decimalSeparator={','}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </p>
          )}
        </Card>
      </Col>
    );
  }
}
