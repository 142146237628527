import { Button, Col, Input, Modal, Row, Select } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { Label } from '../../components/FormLabel';
import { CATEGORIES } from '../../constants';

const CustomSubmitButton = styled.button`
  background-color: rgb(50, 182, 80);
  padding: 0.75rem 2rem;
  height: 3rem;
  border: 1px solid rgb(46, 165, 73);
  border-radius: 4px;
  color: #fff;
  margin: 1rem 0;
`;

const CustomInput = styled(Input)`
  width: 100%;
`;

const CustomSelect = styled(Select)`
  width: 100%;
`;

const CustomInputNumber = styled.input`
  width: 100%;
`;

const ContainerField = styled.div`
  height: 64px;
  margin: 1.25rem 0;
  width: 100%;
`;

const Error = styled.span`
  display: block;
  font-size: 12px;
  color: red;
`;

export const ServiceSchema = Yup.object().shape({
  service: Yup.string().required('Campo obrigatório'),
  unitControl: Yup.string().required('Campo obrigatório'),
  // period: Yup.number().required('Campo obrigatório'), // remove required condition temporarily
  frequency: Yup.number().required('Campo obrigatório'),
});

const ServiceForm = ({ addService }) => {
  const [modal, setModal] = useState(false);

  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    console.log(values);
    addService({ key: uuidv4(), ...values });
    setSubmitting(false);
    resetForm();
    closeModal();
  };

  return (
    <>
      <Button onClick={openModal}>Adicionar serviço</Button>
      <Modal footer={false} visible={modal} onCancel={closeModal} title={`Novo serviço`}>
        <Formik
          initialValues={{}}
          validationSchema={ServiceSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <>
              <Row>
                <Col span={24}></Col>
                <ContainerField>
                  <Label>Descrição *</Label>
                  <CustomInput
                    name="service"
                    placeholder="Descrição"
                    onChange={(e) => props.setFieldValue('service', e.target.value)}
                    value={props.values.service}
                  />
                  <Error>{props.errors.service}</Error>
                </ContainerField>
              </Row>
              <Row>
                <Col span={24}>
                  <ContainerField>
                    <Label>Categoria *</Label>
                    <CustomSelect
                      name="category"
                      placeholder="Categoria"
                      onChange={(value) => props.setFieldValue('category', value)}
                      value={props.values.category}
                    >
                      {CATEGORIES.map((option, index) => (
                        <Select.Option key={index} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </CustomSelect>
                    <Error>{props.errors.category}</Error>
                  </ContainerField>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <ContainerField>
                    <Label>Un. Controle *</Label>
                    <CustomSelect
                      name="unitControl"
                      placeholder="Un. Controle"
                      onChange={(value) => props.setFieldValue('unitControl', value)}
                      value={props.values.unitControl}
                    >
                      <Select.Option key="KM" value="KM">
                        KM
                      </Select.Option>
                      <Select.Option key="H" value="H">
                        H
                      </Select.Option>
                    </CustomSelect>
                    <Error>{props.errors.unitControl}</Error>
                  </ContainerField>
                </Col>
                <Col span={8}>
                  <ContainerField>
                    <Label>Frequência *</Label>
                    <CustomInputNumber
                      name="frequency"
                      placeholder="Frequência (KM ou H)"
                      type="number"
                      className="ant-input"
                      onChange={(e) => props.setFieldValue('frequency', e.target.value)}
                      value={props.values.frequency}
                    />
                    <Error>{props.errors.frequency}</Error>
                  </ContainerField>
                </Col>
                <Col span={8}>
                  <ContainerField>
                    <Label>Periodicidade (dias) *</Label>
                    <CustomInputNumber
                      name="period"
                      placeholder="Ex.: 360"
                      type="number"
                      className="ant-input"
                      onChange={(e) => props.setFieldValue('period', e.target.value)}
                      onBlur={props.handleBlur}
                      value={props.values.period}
                    />
                    <Error>{props.errors.period}</Error>
                  </ContainerField>
                </Col>
              </Row>
              <CustomSubmitButton onClick={props.handleSubmit}>Enviar</CustomSubmitButton>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ServiceForm;
