import { Col, DatePicker, Input, Row, Select } from 'antd';
import pt_BR from 'antd/lib/locale-provider/pt_BR';
import { ErrorMessage, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { db } from '../../../firebaseConfig';
import { Subtitle } from '../../../components/PageSubtitle/styles';
import { useCallback } from 'react';

const { TextArea } = Input;

const { Option } = Select;

const PurchaseSchema = Yup.object().shape({
  unitPrice: Yup.number().required('Campo obrigatório'),
  amount: Yup.number().required('Campo obrigatório'),
  provider: Yup.object().typeError('Campo obrigatório'),
});

const FormPurchase = ({ part, organization }) => {
  const { id } = useParams();
  const history = useHistory();
  const [providers, setProviders] = useState([]);

  const fetchData = useCallback(async () => {
    const providersSnap = await db
      .collection('organizations')
      .doc(organization)
      .collection('providers')
      .get();

    const providers = providersSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setProviders(providers);
  }, [organization]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCreatePurchase = async (values, part) => {
    const purchase = {
      ...values,
      parts: [part],
    };

    await db
      .collection('organizations')
      .doc(organization)
      .collection('purchases')
      .add(purchase)
      .then(() => history.push('/parts'))
      .catch((e) => console.error(e));
  };

  return (
    <Formik
      initialValues={{
        date: new Date(),
        provider: null,
        unitPrice: '',
        amount: '',
        description: null,
      }}
      validationSchema={PurchaseSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleCreatePurchase(values, part);
        setSubmitting(false);
      }}
    >
      {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
        <div style={{ margin: '0 auto' }}>
          <div className="part-info">
            <Subtitle>Informações da compra</Subtitle>
            <p className="part-instruction">Insira as informações referente a compra deste item</p>
            <Row gutter={16}>
              <Col span={12}>
                <div className="part-info-field">
                  <label className="label-form" htmlFor="provider">
                    Fornecedor:
                    <ErrorMessage name="provider">
                      {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                    </ErrorMessage>
                  </label>
                  <Select
                    name="provider"
                    showSearch
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    onChange={(value, option) => {
                      const provider = option.props.provider;
                      setFieldValue('provider', provider);
                    }}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {providers.map((el, index) => (
                      <Option key={index} value={el.company} provider={el}>
                        {el.company}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
              <Col span={12}>
                <div className="part-info-field">
                  <label className="label-form" htmlFor="initDate">
                    Comprado em:
                    <ErrorMessage name="initDate">
                      {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                    </ErrorMessage>
                  </label>
                  <DatePicker
                    allowClear={false}
                    style={{ width: '100%' }}
                    onChange={(date) => setFieldValue('date', date._d)}
                    locale={pt_BR}
                    format="DD/MM/YYYY"
                    defaultValue={values.date ? moment(values.date, 'DD/MM/YYYY') : null}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <div className="part-info-field">
                  <label className="label-form" htmlFor="unitPrice">
                    Preço unitário:
                    <ErrorMessage name="unitPrice">
                      {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                    </ErrorMessage>
                  </label>
                  <Input
                    name="unitPrice"
                    onChange={handleChange}
                    addonBefore="R$"
                    type="number"
                    placeholder="Ex.: 150.75"
                  />
                </div>
              </Col>
              <Col span={12}>
                <div className="part-info-field">
                  <label className="label-form" htmlFor="amount">
                    Quantidade:
                    <ErrorMessage name="amount">
                      {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                    </ErrorMessage>
                  </label>
                  <Input
                    name="amount"
                    onChange={handleChange}
                    addonAfter={part.unitControl}
                    type="number"
                    placeholder="Ex.: 50"
                  />
                </div>
              </Col>
            </Row>

            <div className="part-info-field">
              <label className="label-form" htmlFor="description">
                Descrição da compra:
                <ErrorMessage name="description">
                  {(errorMessage) => <div className="yup-error">{errorMessage}</div>}
                </ErrorMessage>
              </label>
              <TextArea
                name="description"
                onChange={handleChange}
                rows={4}
                placeholder="Insira uma descrição da compra realizada"
              />
            </div>
            <div style={{ textAlign: 'right' }}>
              <p style={{ color: 'gray' }}>
                Valor total:{' '}
                <span
                  style={{
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                    color: '#333',
                  }}
                >
                  {`R$ ${values.unitPrice * values.amount}`}
                </span>
              </p>
            </div>
          </div>
          <div style={{ textAlign: 'center', width: '50vw', margin: '0 auto' }}>
            <button type="submit" onClick={handleSubmit} className="submit-part">
              {!id ? 'Submeter a compra' : 'Submeter alterações'}
            </button>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default withRouter(FormPurchase);
