import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyDYKD4H9O2g852UxvD7xZB6SdIu9JCwQkc",
  authDomain: "maintenance-8aa35.firebaseapp.com",
  databaseURL: "https://maintenance-8aa35.firebaseio.com",
  projectId: "maintenance-8aa35",
  storageBucket: "maintenance-8aa35.appspot.com",
  messagingSenderId: "220152280557"
};

export const app = firebase.initializeApp(config);
export const db = firebase.firestore(app);
